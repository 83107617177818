import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../shared/components/NotFound/NotFound';
import { useEffect } from 'react';
import { Loading } from 'shared/components/Loading/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../helpers/api';
import { Order } from './order/Order.component';
import { useDispatch, useSelector } from 'react-redux';
import { selectMe, setAccessToken, setAuthResponse, setAuthStatus, setMeInfo } from './authentication/authSlice';
import { useAppSelector } from 'app/hooks';
import { OrderDetailMobile } from './mobile/OrderDetailMobile/OrderDetailMobile.component';
import { LogoutHandler } from 'auth/LogoutHandler';
import DefaultLayout from 'shared/components/Layout/Layout';
import { selectOrderErrorResponse } from './order/Order.slice';
import Home from './Home/Home';
import { getMe } from './authentication/auth.api';
// import GlobalStyle from './GlobalStyle';

export const App = (): JSX.Element => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();
  const authCheckStatus = useAppSelector((state) => state.auth.status);
  const getMeInfo = useSelector(selectMe);
  const OrderError = useSelector(selectOrderErrorResponse);
  useEffect(() => {
    const getAccessToken = async () => {
      try {
        console.log('----Start getAccessTokenSilently----');
        const token = await getAccessTokenSilently();
        console.log('Token: ' + token);
        if (token) {
          api.setToken(token);
          dispatch(setAccessToken(token));
          dispatch(setAuthResponse(user));
          dispatch(setAuthStatus('loaded'));
          dispatch(setMeInfo(await getMe()));
          process.env.NODE_ENV === 'development' && console.log(' (DEV) access_token: ', token);
        }
      } catch (error) {
        console.log('----Error when getAccessTokenSilently----');
        console.error(error);
        // Guest mode
        dispatch(setAuthStatus('loaded'));
      }
    };

    // run
    if (!isLoading) {
      getAccessToken();
      // if (isAuthenticated) {
      //   getAccessToken();
      // } else {
      //   dispatch(setAuthStatus('loaded'));
      // }
    }
  }, [dispatch, getAccessTokenSilently, isLoading, isAuthenticated]);
  const isNotFound = OrderError?.statusCode === 404 || OrderError?.statusCode === 403;
  if (authCheckStatus !== 'loaded' || (isAuthenticated && !getMeInfo.roles)) {
    return <Loading />;
  }
  return (
    <Routes>
      <Route path='/*' element={<DefaultLayout />}>
        <Route path='' element={<Home />} />
        <Route path=':resourceToken' element={isNotFound ? <NotFound /> : <Order />} />
        <Route path=':resourceToken/:tabName' element={isNotFound ? <NotFound /> : <OrderDetailMobile />} />
        <Route path='logoutCallback' element={<LogoutHandler />} />
        <Route path='*' element={<NotFound />}></Route>
      </Route>
    </Routes>
  );
};

import { AuditModel } from 'shared/models/audit.model';
import { IDModel } from 'shared/models/id.model';

export enum PaymentType {
  Card = 'Card',
  Check = 'Check',
  Cash = 'Cash',
  Manual = 'Manual',
  Wire = 'Wire',
}

interface PaymentBase {
  comment?: string;
  amount?: number;
}

interface ReadPaymentBase extends PaymentBase, IDModel, AuditModel {
  type?: PaymentType;
  paymentNum?: number;
  paymentDate?: string;
}

export interface CashPayment extends ReadPaymentBase {}
export interface ManualPayment extends ReadPaymentBase {}

export interface CheckPayment extends ReadPaymentBase {}

export interface CreditCardPayment extends ReadPaymentBase {
  merchantId?: string;
  accountNumber?: string;
  accountType?: string;
  transactionId?: string;
  status?: TransactionStatus;
  reason?: string;
  reasonCode?: number;
}

export enum TransactionStatus {
  Approved = 1,
  Declined = 2,
  Error = 3,
  HeldForReview = 4,
}

interface CreatePaymentBase {
  paymentType: PaymentType;
  amount: number;
  comment?: string;
  logikId: string;
  masterId: string;
  siteId: string;
  paymentDate: string;
}

export interface CreateCashPayment extends CreatePaymentBase {}
export interface CreateManualPayment extends CreatePaymentBase {}

export interface CreateCheckPayment extends CreatePaymentBase {
  checkNumber: string;
}

export interface CreateCreditCardPayment extends CreatePaymentBase {
  opaqueData: AuthorizeNet.OpaqueData;
  merchantId?: string;
}

export type PaymentResponse = CashPayment | CheckPayment | CreditCardPayment | ManualPayment;
export type CreatePayment = CreateCashPayment | CreateCheckPayment | CreateCreditCardPayment | CreateManualPayment;

export interface Merchant {
  id: string;
  displayName: string;
  isSandboxAccount: boolean;
  inTestMode: boolean;
  publicClientKey: string;
  apiLoginId: string;
}

import { Dropdown } from 'antd';
import styled from 'styled-components';

export const Divider = styled.span``;
export const OrderProgressWrapper = styled.div`
  background: linear-gradient(180deg, #edeae3 2.56%, #bbb5ab 155.11%);
  box-shadow: 0px 20px 60px rgba(106, 106, 99, 0.3);
  border-radius: 15px;
`;
export const OrderProgressStatusProduct = styled.div`
  background: linear-gradient(180deg, #b77250 0%, #8d553a 100%);
`;
export const OrderProgressStatusProductDropdown = styled(Dropdown)`
  background: linear-gradient(180deg, #b77250 0%, #8d553a 100%);
`;

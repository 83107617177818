import { ColumnsType } from 'antd/es/table';
import { selectOrderLine } from 'features/order/Order.slice';
import { toShortString } from 'helpers/dateFormats';
import { generateStatus } from 'helpers/generateStatus';
import moneyFormat from 'helpers/moneyFormat';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PaymentSchedule as PaymentScheduleModel } from 'shared/models/payment-schedule.model';
import { useParams } from 'react-router';
import Button from '../../../shared/components/Button/Button';
import MobileTable from '../../../shared/components/MobileTable/MobileTable';
import PaymentModal from '../Payment.modal';
import { selectPaymentSchedules } from '../Payment.slice';
import isPayable from 'utils/isPayable';
import isRtoOrder from 'utils/isRtoOrder';

function TotalComponentMobile() {
  const PaymentSchedules = useSelector(selectPaymentSchedules);
  return (
    <>
      <div className='min-[280px]:hidden min-[375px]:contents md:hidden'>
        <div className='mt-[30px] flex flex-wrap'>
          <div className='flex flex-wrap mx-auto'>
            <div className='bg-base-1 px-[18px] py-[15px] rounded-[15px] mb-[10px] mr-[28px] md:h-[116px]'>
              <div className='w-[129px]'>
                <div className='text-xs text-base-3 font-medium'>Scheduled Amount</div>
                <div className='text-xl text-gray-7 font-medium'>
                  {moneyFormat(PaymentSchedules.reduce((a, b) => a + b.amount, 0))}
                </div>
                <div className='text-xs text-gray-9 italic font-normal'>Your Project total</div>
              </div>
            </div>
            <div className='bg-base-1 px-[18px] py-[15px] rounded-[15px] md:h-[116px]'>
              <div className='w-[129px]'>
                <div className='text-xs text-base-3 font-medium'>Payments Applied</div>
                <div className='text-xl text-gray-7 font-medium'>
                  {moneyFormat(PaymentSchedules.reduce((a, b) => a + b.appliedAmount, 0))}
                </div>
                <div className='text-xs text-gray-9 italic font-normal'>How we applied your payments</div>
              </div>
            </div>
          </div>
          <div className='flex flex-wrap mx-auto'>
            <div className='bg-base-1 px-[18px] py-[15px] rounded-[15px] md:h-[116px]'>
              <div className='w-[129px]'>
                <div className='text-xs text-base-3 font-medium'>Balance</div>
                <div className='text-xl text-gray-7 font-medium'>
                  {moneyFormat(PaymentSchedules.reduce((a, b) => (b.balance ? a + b.balance : a), 0))}
                </div>
                <div className='text-xs text-gray-9 italic font-normal'>Remaining Balance on your Project</div>
              </div>
            </div>
            <div className='bg-base-1 px-[18px] py-[15px] rounded-[15px] mt-[10px] ml-[20px] md:h-[116px] md:mt-[0px]'>
              <div className='w-[129px]'>
                <div className='text-xs text-base-3 font-medium'>Current Amount Due</div>
                <div className='text-xl text-gray-7 font-medium'>
                  {moneyFormat(PaymentSchedules.reduce((a, b) => (b.amountDue ? a + b.amountDue : a), 0))}
                </div>
                <div className='text-xs text-gray-9 italic font-normal'>This is the total currently due</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function TotalComponentTablet() {
  const PaymentSchedules = useSelector(selectPaymentSchedules);
  return (
    <>
      <div className='min-[375px]:hidden md:contents'>
        <div className='mt-[30px] flex flex-wrap justify-evenly'>
          <div className='bg-base-1 px-[18px] py-[15px] rounded-[15px] mb-[10px] md:h-[116px]'>
            <div className='w-[129px]'>
              <div className='text-xs text-base-3 font-medium'>Scheduled Amount</div>
              <div className='text-xl text-gray-7 font-medium'>
                {moneyFormat(PaymentSchedules.reduce((a, b) => a + b.amount, 0))}
              </div>
              <div className='text-xs text-gray-9 italic font-normal'>Your Project total</div>
            </div>
          </div>
          <div className='bg-base-1 px-[18px] py-[15px] rounded-[15px] md:h-[116px] min-[280px]:mb-[10px] md:mb-[10px]'>
            <div className='w-[129px]'>
              <div className='text-xs text-base-3 font-medium'>Payments Applied</div>
              <div className='text-xl text-gray-7 font-medium'>
                {moneyFormat(PaymentSchedules.reduce((a, b) => a + b.appliedAmount, 0))}
              </div>
              <div className='text-xs text-gray-9 italic font-normal'>How we applied your payments</div>
            </div>
          </div>

          <div className='bg-base-1 px-[18px] py-[15px] rounded-[15px] md:h-[116px]'>
            <div className='w-[129px]'>
              <div className='text-xs text-base-3 font-medium'>Balance</div>
              <div className='text-xl text-gray-7 font-medium'>
                {moneyFormat(PaymentSchedules.reduce((a, b) => (b.balance ? a + b.balance : a), 0))}
              </div>
              <div className='text-xs text-gray-9 italic font-normal'>Remaining Balance on your Project</div>
            </div>
          </div>
          <div className='bg-base-1 px-[18px] py-[15px] rounded-[15px] mt-[10px] md:h-[116px] md:mt-[0px]'>
            <div className='w-[129px]'>
              <div className='text-xs text-base-3 font-medium'>Current Amount Due</div>
              <div className='text-xl text-gray-7 font-medium'>
                {moneyFormat(PaymentSchedules.reduce((a, b) => (b.amountDue ? a + b.amountDue : a), 0))}
              </div>
              <div className='text-xs text-gray-9 italic font-normal'>This is the total currently due</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PaymentScheduleMobile() {
  const classHeader = 'px-5 py-5 text-xs';
  const classElement = 'text-xs h-full';
  const classPaddingElement = 'px-5 py-4';
  const arrColumnType: ColumnsType<PaymentScheduleModel> = [
    {
      title: () => {
        return (
          <div
            className={`text-left text-base-3 bg-base-1 rounded-tr-lg shadow-[10px_10px_10px_0_rgba(0,0,0,0.2)] ${classHeader}`}>
            Description
          </div>
        );
      },
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      width: 140,
      render: (text, record, index) => {
        if (index === PaymentSchedules.length - 1) {
          return (
            <>
              <div className={`bg-base-1 rounded-br-lg shadow-[10px_10px_10px_0_rgba(0,0,0,0.2)] ${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>{text ? text : '-'}</div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`bg-base-1 shadow-[10px_10px_10px_0_rgba(0,0,0,0.2)] ${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement} `}>{text ? text : '-'}</div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-left text-base-3`}>Status</div>;
      },
      width: 160,
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => {
        if (index === PaymentSchedules.length - 1) {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>
                  {record.paymentDueDate
                    ? statusTransform(record.amountDue ?? 0, record.paymentDueDate.toString())
                    : '-'}
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>
                  {record.paymentDueDate
                    ? statusTransform(record.amountDue ?? 0, record.paymentDueDate.toString())
                    : '-'}
                </div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-right text-base-3`}>Scheduled Amount</div>;
      },
      width: 160,
      dataIndex: 'amount',
      key: 'amount',
      render: (value, record, index) => {
        if (index === PaymentSchedules.length - 1) {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>
                  {value !== undefined && value !== null ? moneyFormat(value) : '–'}
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>
                  {value !== undefined && value !== null ? moneyFormat(value) : '–'}
                </div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-right text-base-3`}>Payments Applied</div>;
      },
      width: 160,
      dataIndex: 'appliedAmount',
      key: 'appliedAmount',
      render: (value, record, index) => {
        if (index === PaymentSchedules.length - 1) {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>
                  {value !== undefined && value !== null ? moneyFormat(value) : '–'}
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>
                  {value !== undefined && value !== null ? moneyFormat(value) : '–'}
                </div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-right text-base-3`}>Balance</div>;
      },
      width: 160,
      dataIndex: 'balance',
      key: 'balance',
      render: (value, record, index) => {
        if (index === PaymentSchedules.length - 1) {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>
                  {value !== undefined && value !== null ? moneyFormat(value) : '–'}
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>
                  {value !== undefined && value !== null ? moneyFormat(value) : '–'}
                </div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-right text-base-3`}>Current Amount Due</div>;
      },
      width: 160,
      dataIndex: 'amountDue',
      key: 'amountDue',
      render: (value, record, index) => {
        if (index === PaymentSchedules.length - 1) {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>
                  {value !== undefined && value !== null ? moneyFormat(value) : '–'}
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>
                  {value !== undefined && value !== null ? moneyFormat(value) : '–'}
                </div>
              </div>
            </>
          );
        }
      },
    },
  ];
  const orderLineState = useSelector(selectOrderLine);
  const [paymentModelKey, setPaymentModelKey] = useState(new Date().getTime());
  const { resourceToken = '' } = useParams();
  const [open, setOpen] = useState(false);
  const OrderInfo = useSelector(selectOrderLine);
  useEffect(() => {
    if (resourceToken) {
      setPaymentModelKey(new Date().getTime());
    }
  }, [resourceToken]);
  const statusTransform = (amountDue: number | undefined | null, dueDate: string) => {
    const status = generateStatus(amountDue, dueDate);
    return (
      <div className=''>
        {status}
        {status !== 'Paid' && <p className='text-[10px] text-gray-9'>{`Due ${toShortString(dueDate)}`}</p>}
      </div>
    );
  };
  const PaymentSchedules = useSelector(selectPaymentSchedules);
  return (
    <>
      {/*  Table */}
      <MobileTable dataSource={PaymentSchedules} columns={arrColumnType} />

      <TotalComponentMobile />
      <TotalComponentTablet />

      {/* Payment Button */}
      <div className='w-full flex items-center justify-center mt-5'>
        {isPayable(orderLineState) && (
          <Button
            disabled={orderLineState.currentAmountDue === 0 && isRtoOrder(orderLineState)}
            buttonType='primary'
            className='px-[30px] py-[6px] rounded-[30px] tracking-[1px] mb-[25px] text-[10px]'
            onClick={() => {
              setPaymentModelKey(new Date().getTime());
              setOpen(true);
            }}>
            MAKE A PAYMENT
          </Button>
        )}

        <PaymentModal
          open={open}
          key={paymentModelKey}
          setOpen={setOpen}
          amountDue={OrderInfo.currentAmountDue ?? 0}></PaymentModal>
      </div>
    </>
  );
}

export default PaymentScheduleMobile;

import styled from 'styled-components';

export const TableDesktopWrraper = styled.div`
  max-width: 922px;
  .ant-table-content {
    color: var(--base-3);
    .ant-table-row {
      .ant-table-cell {
        min-height: 66px;
        border-bottom: 1px solid var(--gray-6) !important;
      }
    }
  }
  .ant-table-thead {
    tr {
    }
    th {
      border-bottom: 1px solid var(--gray-6) !important;
      padding-top: 12px;
      padding-bottom: 12px;
      vertical-align: middle;
      color: var(--base-3) !important;
      font-weight: 500 !important;
    }
    th:before {
      content: none !important;
    }
  }
  .ant-table-tbody {
    background-color: var(--gray-8);
  }
  .ant-table-summary > tr > td {
    border: none;
  }
`;

import { useAuth0 } from '@auth0/auth0-react';
import { Button, MenuProps } from 'antd';

import ProgressBar, { StepInfo } from '../../../shared/components/ProgressBar/ProgressBar.component';
import { selectMyOrderLines, selectOrderLine, selectOrderLinesToken } from '../Order.slice';
import { useSelector } from 'react-redux';
import { OrderLine } from '../../../shared/models/order-line.model';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderProgressStatusProduct, OrderProgressStatusProductDropdown, OrderProgressWrapper } from '../Order.styles';
import { ReactComponent as ShoppingCartIcon } from 'shared/icons/shopping-cart 1.svg';
import { ReactComponent as BoxIcon } from 'shared/icons/box 1.svg';
import { ReactComponent as HammerIcon } from 'shared/icons/hammer.svg';
import { ReactComponent as ClipBoardIcon } from 'shared/icons/clipboard 1.svg';
import { ReactComponent as TruckIcon } from 'shared/icons/truck 1.svg';
import { useEffect, useState } from 'react';
import { selectMe } from 'features/authentication/authSlice';
import { ADMIN_ROLE } from 'utils/constants';

export const OrderProgress = () => {
  const { isAuthenticated } = useAuth0();
  const OrderInfo: OrderLine = useSelector(selectOrderLine);
  const navigate = useNavigate();
  const { siteId } = useParams();
  const OrderLines = useSelector(selectMyOrderLines);
  const OrderLinesToken = useSelector(selectOrderLinesToken);
  const getMeInfo = useSelector(selectMe);
  const [menuProps, setMenuProps] = useState({});
  useEffect(() => {
    const handleMenuClick: MenuProps['onClick'] = async (e) => {
      navigate(`/${e.key}`);
    };
    const menuPropsTemp = {
      items: OrderLines.map((e, index) => {
        return {
          label: `${e.orderNum} – ${e.productName ?? ''} (${e.width ?? ''}x${e.length ?? ''})`,
          key: `${!siteId ? OrderLinesToken[index]?.token : `orders/${e.siteId}/${e.orderNum}/${e.lineId}`}`,
        };
      }),
      onClick: handleMenuClick,
    };
    setMenuProps(menuPropsTemp);
  }, [OrderLines, OrderLinesToken, siteId, navigate]);

  const statusProgresbar = [
    'Submitted',
    'OrderConfirmation',
    'OrderPrep',
    'Production',
    'QualityCheck',
    'Delivery_install',
    'Completion',
  ];
  const renderStatusProgressBar = statusProgresbar.map((e, index) => {
    const statusIndex = statusProgresbar.findIndex((status) => status === OrderInfo.status);
    return statusIndex < index ? 'UNPROCESSED' : statusIndex > index ? 'PROCESSED' : 'CURRENT';
  });

  const renderFillColorIcon = renderStatusProgressBar.map((e) =>
    e === 'CURRENT' ? '#B77250' : e === 'PROCESSED' ? '#748B89' : '#6A6A63',
  );
  const welcomeText =
    !OrderInfo?.customer?.name && !OrderInfo?.customerName // && !OrderInfo?.store?.description
      ? 'Welcome '
      : `Welcome, ${OrderInfo.customer?.name || OrderInfo?.customerName /*|| OrderInfo.store?.description */}`;
  return (
    <>
      <OrderProgressWrapper
        className='container px-1 py-2 xl:px-10 xl:py-[30px]
       rounded-[15px] w-full max-w-2xl xl:max-w-[1185px] relative'>
        {/* Logo stamp */}
        <img
          src='/ulrich_logo_stamp.svg'
          alt='ulrich_logo_stamp'
          className='absolute w-[30px] h-[30px] xl:w-auto xl:h-auto top-[-10px] right-[-2px] xl:top-[-50px] xl:right-[-50px]'></img>
        <div className='flex flex-col items-center'>
          <div className='text-xs xl:text-xl text-center w-fit flex flex-col items-center'>
            {(!isAuthenticated || getMeInfo.roles?.some((role) => role.name === ADMIN_ROLE)) && (
              <>
                <h3 className='font-medium text-base-3'>{`${welcomeText}! Track the status of your order`}</h3>
                <OrderProgressStatusProduct className='bg-gray-7 rounded-[10px] py-2 px-3 xl:px-4 text-base-2 font-bold mt-3 xl:mt-4 w-fit'>
                  {`${OrderInfo?.orderNum ?? ''} – ${OrderInfo?.productName ?? ''} (${OrderInfo?.width ?? ''}x${
                    OrderInfo?.length ?? ''
                  })`}
                </OrderProgressStatusProduct>
              </>
            )}
            {isAuthenticated && !getMeInfo.roles?.some((role) => role.name === ADMIN_ROLE) && (
              <div className=''>
                <h2 className='font-medium text-base-3'>{`${welcomeText}! Track the status of your order`}</h2>
                <OrderProgressStatusProductDropdown
                  menu={menuProps}
                  className='bg-gray-7 border-none py-2 px-3 xl:px-4 h-auto text-base-2 font-bold !leading-[130%] border-none` rounded-[10px] ml-4 mt-3 xl:mt-4 text-xs xl:text-xl'>
                  <Button>
                    <div className='flex items-center'>
                      <span>{`${OrderInfo?.orderNum ?? ''} – ${OrderInfo?.productName ?? ''} (${
                        OrderInfo?.width ?? ''
                      }x${OrderInfo?.length ?? ''})`}</span>
                      <img className='ml-2 w-[18px] xl:w-auto' src='/icons/down_outline.svg' alt='down_icon' />
                    </div>
                  </Button>
                </OrderProgressStatusProductDropdown>
              </div>
            )}
          </div>
          {OrderInfo.sourceType !== 'P' && (
            <ProgressBar>
              <StepInfo
                className='flex-1'
                icon={
                  <ShoppingCartIcon
                    className='w-[13.5px] h-[13.5px] xl:w-auto xl:h-auto  transition-colors duration-500'
                    fill={renderFillColorIcon[0]}
                    filter='drop-shadow(inset 2px 2px 4px rgba(132, 132, 132, 0.55)) drop-shadow(inset -2px -2px 4px #FFFFFF)'
                  />
                }
                stage='Stage 1'
                description='Order Confirmation'
                status={renderStatusProgressBar[0]}
              />
              <StepInfo
                className='flex-1'
                icon={
                  <BoxIcon
                    className='w-[13.5px] h-[13.5px] xl:w-auto xl:h-auto transition-colors duration-500'
                    fill={renderFillColorIcon[1]}
                    filter='drop-shadow(inset 2px 2px 4px rgba(132, 132, 132, 0.55)) drop-shadow(inset -2px -2px 4px #FFFFFF)'
                  />
                }
                stage='Stage 2'
                description='Order Prep'
                descriptionclassName='max-w-[48px] xl:max-w-fit'
                status={renderStatusProgressBar[1]}
              />
              <StepInfo
                className='flex-1'
                icon={
                  <HammerIcon
                    className='w-[13.5px] h-[13.5px] xl:w-auto xl:h-auto  transition-colors duration-500'
                    fill={renderFillColorIcon[2]}
                    filter='drop-shadow(inset 2px 2px 4px rgba(132, 132, 132, 0.55)) drop-shadow(inset -2px -2px 4px #FFFFFF)'
                  />
                }
                stage='Stage 3'
                description={`${OrderInfo.sourceType === 'K' ? 'Construction' : 'Production Process'}`}
                status={renderStatusProgressBar[2]}
              />
              <StepInfo
                className='flex-1'
                icon={
                  <ClipBoardIcon
                    className='w-[13.5px] h-[13.5px] xl:w-auto xl:h-auto  transition-colors duration-500'
                    fill={renderFillColorIcon[3]}
                    filter='drop-shadow(inset 2px 2px 4px rgba(132, 132, 132, 0.55)) drop-shadow(inset -2px -2px 4px #FFFFFF)'
                  />
                }
                stage='Stage 4'
                description='Quality Check'
                descriptionclassName='max-w-[48px] xl:max-w-fit'
                status={renderStatusProgressBar[3]}
              />
              <StepInfo
                className='flex-1 xl:flex-auto'
                icon={
                  <TruckIcon
                    className='w-[13.5px] h-[13.5px] xl:w-auto xl:h-auto  transition-colors duration-500'
                    fill={renderFillColorIcon[4]}
                    filter='drop-shadow(inset 2px 2px 4px rgba(132, 132, 132, 0.55)) drop-shadow(inset -2px -2px 4px #FFFFFF)'
                  />
                }
                stage='Stage 5'
                description={`${OrderInfo.sourceType === 'K' ? 'Project Complete' : 'Delivery & Install'}`}
                descriptionclassName='max-w-[40px] xl:max-w-fit'
                status={renderStatusProgressBar[4]}
              />
            </ProgressBar>
          )}
        </div>
      </OrderProgressWrapper>
    </>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthResponse, DefaultAuthResponse } from 'shared/models/authResponse.model';
import { defaultGetMeResponse, GetMeResponse } from 'shared/models/get-me.response.model';
import { RootState } from '../../app/store';

export interface AuthState {
  authResponse: AuthResponse;
  accessToken: string;
  me: GetMeResponse;
  loggedIn: boolean;
  status: 'idle' | 'loading' | 'failed' | 'loaded';
  errorMessage?: string;
}
console.log('Load authSlice');
const currentAuthResponseString = localStorage.getItem('authResponse');
let initialAuthResponse: AuthResponse = DefaultAuthResponse;
if (currentAuthResponseString) {
  initialAuthResponse = JSON.parse(currentAuthResponseString);
}

const initialState: AuthState = {
  accessToken: '',
  authResponse: initialAuthResponse,
  me: defaultGetMeResponse,
  loggedIn: !!initialAuthResponse,
  status: 'idle',
  errorMessage: 'Wrong username or password',
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuthResponse: (state, value) => {
      console.log('set authResponse');
      state.authResponse = value.payload;
    },
    setAccessToken: (state, value) => {
      console.log('set token');
      state.accessToken = value.payload;
    },
    setAuthStatus: (state, value) => {
      state.status = value.payload;
    },
    setLoggedIn: (state, value) => {
      state.loggedIn = value.payload;
    },
    setMeInfo: (state, value: PayloadAction<GetMeResponse>) => {
      state.me = value.payload;
    },
  },
});
export const selectAuthResponse = (state: RootState) => state.auth.authResponse;
export const selectLoggedIn = (state: RootState) => state.auth.loggedIn;
export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectMe = (state: RootState) => state.auth.me;
export default authSlice.reducer;
export const { setAccessToken, setAuthResponse, setAuthStatus, setLoggedIn, setMeInfo } = authSlice.actions;

import { BaseOrderLine, defaultBaseOrderLine } from './base-order-line.model';

export interface ConfigDetail {
  configOptions: string;
}

export interface Address {
  title: string;
  subTitle: string;
  fullAddress: string;
  lat?: number;
  long?: number;
  type: string;
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface OrderLine extends BaseOrderLine {
  configDetail: ConfigDetail;
  sourceType?: string;
  addresses: Address[];
}

export const defaultOrderLine = {
  ...defaultBaseOrderLine,
  configDetail: {
    configOptions: '',
  },
  addresses: [],
};

import { Alert, Form, InputRef, notification, Space, Spin } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isRequestNonceResponseSuccess } from 'shared/authorize-net/utils/utils';
import { PaymentFormProps } from './CardPaymentForm.props';
import { CreateCreditCardPayment, Merchant, PaymentType } from 'shared/models/payments.model';
import { FormInstance as RcFormInstance } from 'rc-field-form';
import { PaymentAmountInput } from './PaymentAmountInput';
import { moneyToNumber } from 'shared/antd/components/MoneyInput';
import environment from 'app/configs/environment';
import { getMerchants } from './Payment.api';
import { Input } from 'shared/components/Input/Input';
import Button from 'shared/components/Button/Button';
import { FormControlLabel as FormLabel, ErrorMessage } from 'shared/components/Form/Form';
import { CreditCardInput } from 'shared/antd/components/CreditCardInput';

export type CardPaymentProps = PaymentFormProps & {
  siteId: string;
};

type CardPaymentFormState = {
  number: string;
  exp_date: {
    month: string;
    year: string;
  };
  code: string;
  name_on_card: string;
  amount: string;
};
export const CardPaymentForm = ({ onFinish, onError, onCancel, amountDue, siteId }: CardPaymentProps): JSX.Element => {
  const [form] = Form.useForm<CardPaymentFormState>();
  const [scriptLoading, setScriptLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<AuthorizeNet.ResponseMessage[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [merchant, setMerchant] = useState<Merchant | null>();
  const merchantLoading = useRef<boolean>(false);
  const {
    AUTHORIZENET_ACCEPT_JS_SANDBOX_URL: sandboxAuthorizeJsUrl,
    AUTHORIZENET_ACCEPT_JS_PROD_URL: prodAuthorizeJsUrl,
  } = environment.AUTHORIZE_NET;

  const monthInput = useRef<InputRef>(null);
  const yearInput = useRef<InputRef>(null);

  const submitPayment = useCallback(
    (values: CardPaymentFormState): void => {
      if (merchant) {
        const authData: AuthorizeNet.AuthData = {
          apiLoginID: merchant?.apiLoginId,
          clientKey: merchant?.publicClientKey,
        };

        const cardData: AuthorizeNet.CardData = {
          cardNumber: values?.number.split(' ').join(''), // normalize masked credit card number back to number
          month: values.exp_date?.month ?? 0,
          year: values.exp_date?.year ?? 0,
          cardCode: values.code,
          fullName: values.name_on_card,
        };

        try {
          setErrors([]);
          setProcessing(true);
          window.Accept.dispatchData(
            {
              authData,
              cardData,
            },
            async (response: AuthorizeNet.RequestNonceResponse): Promise<void> => {
              setProcessing(false);
              if (isRequestNonceResponseSuccess(response)) {
                const payload = {
                  opaqueData: response.opaqueData,
                  amount: moneyToNumber(values.amount),
                  paymentType: PaymentType.Card,
                  siteId,
                } as CreateCreditCardPayment;
                onFinish(payload);
              } else {
                console.error('payment gateway response', response);
                setErrors(response.messages.message);
                onError(new Error('Failed to make payment. Card authorization failed'));
              }
            },
          );
        } catch (err) {
          onError(err);
          notification.error({
            message: <ErrorMessage text={'Failed to initiate payment'} />,
            duration: 5,
            placement: 'bottomLeft',
          });
          setProcessing(false);
        }
      }
    },
    [merchant, onError],
  );
  useEffect(() => {
    if (!merchantLoading.current && merchant === undefined && siteId) {
      merchantLoading.current = true;
      setMerchant(null);
      setTimeout(() => {
        getMerchants(siteId)
          .then(setMerchant)
          .catch(() => setMerchant(null))
          .finally(() => (merchantLoading.current = false));
      }, 300);
    }
  }, [merchant, siteId]);

  useEffect(() => {
    if (merchant) {
      const scriptId = 'authorizenet-script';
      if (!document.getElementById(scriptId)) {
        setScriptLoading(true);
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = merchant.isSandboxAccount ? sandboxAuthorizeJsUrl : prodAuthorizeJsUrl;
        script.defer = true;
        script.onload = () => setScriptLoading(false);

        document.body.appendChild(script);

        return () => {
          if (script) script.remove();
        };
      }
    }
  }, [merchant]);

  /**
   * This function detects if value of Month input reaches max-length (2), it will focus to the Year input.
   */
  const focusOntoYearInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    // if (val.length <= 2) {
    //   this.setState({ value: e.target.value });
    // }
    if (value.length === 2) {
      yearInput?.current?.focus();
    }
  };

  /**
   * This function detects if user type 'Backspace' or 'Del' key onto an empty Year input, it will focus back to Month.
   */
  const focusBackToMonthInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {
      key,
      currentTarget: { value },
    } = e;
    if ((value === '' || value === null) && (key === 'Backspace' || key === 'Delete')) {
      e.preventDefault();
      monthInput?.current?.focus();
    }
  };

  return (
    /* (dev) change spinning conditions below to have access to UI without working API  */
    <Spin spinning={merchantLoading.current || scriptLoading}>
      {!merchant && !merchantLoading.current && (
        <Alert message={<ErrorMessage text={'Failed to acquire merchant info'} />} type='error' className='mb-[20px]' />
      )}
      {/* using 'noValidate' to hide default HTML validation tooltip on form submit */}
      <Form
        noValidate
        form={form}
        layout='vertical'
        requiredMark={true}
        onFinish={submitPayment}
        colon={false}
        labelWrap={false}>
        <Form.Item
          label={<FormLabel text={'Credit Card'} />}
          name='number'
          rules={[
            {
              required: true,
              message: <ErrorMessage text={'Credit Card Number is required'} />,
            },
            {
              validator: (_, value) => {
                const normalizedNum = value.split(' ').join('');
                if (normalizedNum && 13 <= normalizedNum.length && normalizedNum.length <= 16) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(<ErrorMessage text={`Credit Card Number's length must be 13 or 16 digits`} />);
                }
              },
              validateTrigger: 'onSubmit',
              message: <ErrorMessage text={`Credit Card Number's length must be 13 or 16 digits`} />,
            },
          ]}
          validateFirst={true}>
          <CreditCardInput minLength={13} maxLength={16} autoComplete='cc-number' />
        </Form.Item>

        <Space direction='horizontal' size='large' align='start'>
          <Form.Item
            className='exp-date'
            label={<FormLabel text={'Expiration Date'} />}
            name={['exp_date']}
            rules={[
              (props: RcFormInstance<CardPaymentFormState>) => ({
                validator(_, exp_date) {
                  if (!exp_date) {
                    // if on form submit and state 'exp_date' is invalid, do not resolve validator, reject error right away
                    return Promise.reject(<ErrorMessage text={'Expiration Date is required'} />);
                  } else {
                    // only resolve validator if 'exp_date' is valid
                    let { month, year } = exp_date;

                    if (!month && !year) {
                      return Promise.reject(<ErrorMessage text={'Expiration Date is required'} />);
                    }

                    if (!month) return Promise.reject(<ErrorMessage text={'Month is required'} />);

                    if (!Number.isFinite(Number.parseInt(month)))
                      return Promise.reject(<ErrorMessage text={'Month must be a number between 1 and 12'} />);

                    month = Number(month);
                    if (month < 1 || month > 12)
                      return Promise.reject(<ErrorMessage text={'Month must be a number between 1 and 12'} />);

                    if (!year) return Promise.reject(<ErrorMessage text={'Year is required'} />);
                    if (!Number.isFinite(Number.parseInt(year))) return Promise.reject('Month must be a valid number');

                    year = Number(year) + 2000;

                    const currentYear = new Date().getFullYear();
                    const currentMonth = new Date().getMonth() + 1;

                    if (year < currentYear) return Promise.reject(<ErrorMessage text={`Year can't be in the past`} />);
                    if (year === currentYear && month < currentMonth)
                      return Promise.reject(<ErrorMessage text={`Expiration date can't be in the past`} />);

                    return Promise.resolve();
                  }
                },
                validateTrigger: ['onChange', 'onBlur', 'onSubmit', 'onEnter'],
                required: true,
              }),
            ]}
            validateTrigger={['onChange', 'onBlur', 'onSubmit', 'onEnter']}>
            <Input.Group compact className='w-[76px] max-w-[76px] !flex !p-0 relative'>
              {/* (MM/YY) Input Group */}
              <Form.Item name={['exp_date', 'month']} noStyle>
                <Input
                  className='exp-date-month pr-[0px] bg-transparent !rounded-tr-none !rounded-br-none !border-r-transparent !shadow-none'
                  minLength={2}
                  maxLength={2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    focusOntoYearInput(e);
                    form.validateFields(['exp_date']);
                  }}
                  placeholder={'00'}
                  autoComplete='cc-exp-month'
                  ref={monthInput}
                />
              </Form.Item>
              <span
                className='text-base-3 h-[42px] leading-[42px]'
                style={{ position: 'absolute', left: '50%', transform: `translateX(-50%)` }}>
                /
              </span>
              <Form.Item name={['exp_date', 'year']} noStyle>
                <Input
                  className='exp-date-year pl-[6px] bg-transparent !rounded-tl-none !rounded-bl-none !border-l-transparent !shadow-none'
                  minLength={2}
                  maxLength={2}
                  onChange={() => form.validateFields(['exp_date'])}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => focusBackToMonthInput(e)}
                  placeholder={'00'}
                  autoComplete='cc-exp-year'
                  ref={yearInput}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item
            label={<FormLabel text={'CCV'} />}
            name='code'
            rules={[
              {
                required: true,
                message: <ErrorMessage text={'Security Code is required'} />,
              },
            ]}
            validateFirst={true}>
            <Input.Password
              maxLength={4}
              minLength={3}
              placeholder='&#42;&#42;&#42;'
              autoComplete='cc-csc'
              className='w-[85px]'
            />
          </Form.Item>
        </Space>

        <Form.Item
          label={<FormLabel text={'Full Name on Card'} />}
          name='name_on_card'
          rules={[
            {
              required: true,
              message: <ErrorMessage text={'Card Holder Name is required'} />,
            },
          ]}>
          <Input placeholder={'Full Name on Card'} autoComplete='cc-name' />
        </Form.Item>

        <PaymentAmountInput defaultValue={amountDue} />

        <Button
          className='px-[30px] py-1.5 xl:py-[13px] rounded-[30px]'
          buttonType='primary'
          htmlType='submit'
          loading={processing}
          disabled={processing && !merchant}>
          Pay Now
        </Button>

        {errors.length > 0 &&
          errors.map((err) => (
            <Alert className='mt-[20px]' message={<ErrorMessage text={err.text} />} type='error' key={err.code} />
          ))}
      </Form>
    </Spin>
  );
};

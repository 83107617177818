import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { BrowserRouter } from 'react-router-dom';
import './index.sass';
import { App } from './features/App';
import { ConfigProvider } from 'antd';
import { Auth0ProviderWithHistory } from './auth/Auth0ProviderWithHistory';
import { injectStore } from './helpers/api';
injectStore(store);
const container = document.getElementById('root')!;
const root = createRoot(container);

const antdGlobalConfigs = {
  token: {
    colorPrimary: '#B77250',
    fontFamily: 'Roboto',
    fontSize: 16,
  },
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider theme={antdGlobalConfigs}>
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

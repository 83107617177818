import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorResponse } from 'shared/models/error.response.model';
import { OrderLineToken } from 'shared/models/token.response.model';
import { RootState } from 'app/store';
import { BaseOrderLine } from 'shared/models/base-order-line.model';
import { defaultOrderLine, OrderLine } from 'shared/models/order-line.model';
import {
  getBaseOrderLine,
  getMyOrderLines,
  get3dOrderConfigs,
  getOrderLine,
  getOrderLinesToken,
  getOrderPurchaseInfo,
} from './Order.api';
import { PurchaseInfo, defaultPurchaseInfo } from 'shared/models/configuration.response';
import is3dOrderLine from 'utils/is3dOrderLine';

export interface OrderState {
  myOrderLines: BaseOrderLine[];
  orderLine: OrderLine;
  is3d?: boolean;
  OrderLinesToken: OrderLineToken[];
  configs: any[];
  purchaseInfo: PurchaseInfo;
  status: 'idle' | 'loading' | 'failed' | 'loaded';
  errorMessage?: string;
  errorResponse?: ErrorResponse;

  // err for individual fields
  configsErrorResponse?: ErrorResponse;
  purchaseInfoErrorResponse?: ErrorResponse;

  // reducer status for individual fields
  configStatus: 'idle' | 'loading' | 'failed' | 'loaded';
}

const initialState: OrderState = {
  myOrderLines: [],
  OrderLinesToken: [],
  orderLine: defaultOrderLine,

  // only one of these two will be fetched at a time, whether order is 3D or not
  configs: [],
  purchaseInfo: defaultPurchaseInfo,

  status: 'idle',
  errorMessage: undefined,
  errorResponse: undefined,

  configsErrorResponse: undefined,
  configStatus: 'idle',
};

export const fetchBaseOrderLineAsync = createAsyncThunk<
  BaseOrderLine,
  {
    resourceToken: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>('order/fetchBaseOrderline', async ({ resourceToken }: { resourceToken: string }, { rejectWithValue }) => {
  try {
    const response = await getBaseOrderLine(resourceToken);
    return response;
  } catch (_error) {
    const error = _error as AxiosError;
    const dataErrorResponse = error.response?.data as ErrorResponse;
    return rejectWithValue(dataErrorResponse);
  }
});

export const fetchOrderLineAsync = createAsyncThunk<
  OrderLine,
  {
    resourceToken: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>('order/fetchOrderline', async ({ resourceToken }: { resourceToken: string }, { rejectWithValue }) => {
  try {
    const response = await getOrderLine(resourceToken);
    return response;
  } catch (_error) {
    const error = _error as AxiosError;
    const dataErrorResponse = error.response?.data as ErrorResponse;
    return rejectWithValue(dataErrorResponse);
  }
});

export const fetchMyOrderLines = createAsyncThunk('order/fetchMyOrderLines', async () => {
  const response = await getMyOrderLines();
  return response;
});

export const fetchOrderLinesToken = createAsyncThunk('order/fetchOrderLinesToken', async () => {
  const response = await getOrderLinesToken();
  return response;
});

export const fetch3dOrderConfigs = createAsyncThunk<
  any[],
  {
    resourceToken: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>('order/fetch3dOrderConfigs', async ({ resourceToken }: { resourceToken: string }, { rejectWithValue }) => {
  try {
    console.debug('----- Dispatch fetch OrderConfigs! -----');
    const response = await get3dOrderConfigs(resourceToken);
    const { configOptions: stringifiedConfigs } = response;
    if (stringifiedConfigs != null && stringifiedConfigs !== undefined) {
      try {
        return JSON.parse(stringifiedConfigs);
      } catch (_error) {
        const error = _error as ErrorResponse;
        return rejectWithValue(error);
      }
    }
  } catch (_error) {
    const error = _error as AxiosError<any>;

    // if response error, get statusCode & message directly from response (instead of from response.data)
    const dataErrorResponse = {
      statusCode: error.response!.status,
      message: error.response!.statusText,
    };
    return rejectWithValue(dataErrorResponse);
  }
});

export const fetchOrderPurchaseInfo = createAsyncThunk<
  PurchaseInfo,
  {
    resourceToken: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>('order/fetchOrderPurchaseInfo', async ({ resourceToken }: { resourceToken: string }, { rejectWithValue }) => {
  try {
    console.debug('----- Dispatch fetch PurchaseInfo! -----');
    const response = await getOrderPurchaseInfo(resourceToken);
    return response;
  } catch (_error) {
    const error = _error as AxiosError;

    // make it the same to 3D order configs for now.
    const dataErrorResponse = {
      statusCode: error.response!.status,
      message: error.response!.statusText,
    };
    return rejectWithValue(dataErrorResponse);
  }
});

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    /* Base order line */
    builder
      .addCase(fetchBaseOrderLineAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBaseOrderLineAsync.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.errorMessage = undefined;
        const { configDetail, addresses } = initialState.orderLine;
        state.orderLine = { ...action.payload, configDetail, addresses };
      })
      .addCase(fetchBaseOrderLineAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
        state.errorResponse = action.payload;
      });

    /* Order line */
    builder
      .addCase(fetchOrderLineAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderLineAsync.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.errorMessage = undefined;
        state.orderLine = action.payload;
        state.errorResponse = undefined;
        state.is3d = is3dOrderLine(action.payload);
      })
      .addCase(fetchOrderLineAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
        state.errorResponse = action.payload;
      });

    /* List of order line */
    builder.addCase(fetchMyOrderLines.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.errorMessage = undefined;
      state.myOrderLines = action.payload;
    });

    /* Order line token */
    builder.addCase(fetchOrderLinesToken.fulfilled, (state, action) => {
      state.status = 'loaded';
      state.errorMessage = undefined;
      state.OrderLinesToken = action.payload;
    });

    /* 3D Order configs */
    builder
      .addCase(fetch3dOrderConfigs.pending, (state) => {
        state.status = 'loading';
        state.configStatus = 'loading';
      })
      .addCase(fetch3dOrderConfigs.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.configStatus = 'loaded';
        state.errorMessage = undefined;
        state.configs = [...action.payload];
        state.purchaseInfo = defaultPurchaseInfo;
      })
      .addCase(fetch3dOrderConfigs.rejected, (state, action) => {
        state.status = 'failed';
        state.configStatus = 'failed';
        state.errorMessage = action.error.message;
        state.configsErrorResponse = action.payload; // if failed, respose data will be ErrorResponse instead of array of configs
        state.configs = [];
      });

    /* Regular order configs / purchase info */
    builder
      .addCase(fetchOrderPurchaseInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderPurchaseInfo.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.errorMessage = undefined;
        state.purchaseInfo = action.payload;
        state.configs = [];
      })
      .addCase(fetchOrderPurchaseInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
        state.purchaseInfoErrorResponse = action.payload; // if failed, respose data will be ErrorResponse instead of array of configs
        state.purchaseInfo = defaultPurchaseInfo;
      });
  },
});

/* Overall */
export const selectOrderReducerStatus = (state: RootState) => state.order.status;
export const selectOrderLine = (state: RootState) => state.order.orderLine;
export const selectIs3dOrderLine = (state: RootState) => state.order.is3d;
export const selectMyOrderLines = (state: RootState) => state.order.myOrderLines;
export const selectOrderLinesToken = (state: RootState) => state.order.OrderLinesToken;
export const select3dOrderConfigs = (state: RootState) => state.order.configs;
export const selectOrderPurchaseInfo = (state: RootState) => state.order.purchaseInfo;
export const selectOrderErrorResponse = (state: RootState) => state.order.errorResponse;

/* Order configs */
export const selectOrderConfigsErrorResponse = (state: RootState) => state.order.configsErrorResponse;
// export const selectOrderConfigsStatus = (state: RootState) => state.order.configStatus;

/* Purchase info */
export const selectOrderPurchaseInfoErrorResponse = (state: RootState) => state.order.purchaseInfoErrorResponse;

export default orderSlice.reducer;

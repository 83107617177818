import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../features/authentication/authSlice';
import orderReducer from '../features/order/Order.slice';
import paymentReducer from '../features/payment/Payment.slice';
import documentReducer from '../features/document/Document.slice';
import privateUrlReducer from '../features/privateurl/PrivateUrl.slice';
export const store = configureStore({
  reducer: {
    auth: loginReducer,
    order: orderReducer,
    payment: paymentReducer,
    document: documentReducer,
    privateUrl: privateUrlReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

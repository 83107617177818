enum SITE_ID {
  UBBCLE = 'UBBCLE',
  MFGCA = 'MFGCA',
  MFGVA = 'MFGVA',
}
const UBBCLE = {
  siteId: 'UBBCLE',
  company: 'Ulrich of TX, LLC',
  city: 'Cleburne',
  state: 'TX',
  zip: 76033,
  phone: '817-506-4501',
  add_1: 'PO Box 2797',
};
const MFGCA = {
  siteId: 'MFGCA',
  company: 'Ulrich of CA, LLC',
  city: 'Arvin',
  state: 'CA',
  zip: 93203,
  phone: '817-506-4501',
  add_1: '14346 Sunset Blvd',
};
const MFGVA = {
  siteId: 'MFGVA',
  company: 'Ulrich of VA, LLC',
  city: 'Harrisonburg',
  state: 'VA',
  zip: 22801,
  phone: '817-506-4501',
  add_1: '4777 South Valley Pike',
};
export const getOrderedFrom = (siteId: string) => {
  switch (siteId) {
    case SITE_ID.UBBCLE:
      return UBBCLE;
    case SITE_ID.MFGCA:
      return MFGCA;
    case SITE_ID.MFGVA:
      return MFGVA;
  }
};

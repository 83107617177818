export interface ResourceTokenResponse {
  token?: string;
  key1?: string;
  key2?: string;
  key3?: string;
  key4?: string;
  key5?: string;
  type?: string;
  createdAt?: Date | string;
  createdBy?: string;
  updatedAt?: Date | string;
  updatedBy?: string;
  isDeleted?: boolean;
}
export const DefaultResourceTokenResponse = {
  token: undefined,
  key1: undefined,
  key2: undefined,
  key3: undefined,
  key4: undefined,
  key5: undefined,
  type: undefined,
  createdAt: undefined,
  createdBy: undefined,
  updatedAt: undefined,
  updatedBy: undefined,
  isDeleted: undefined,
};

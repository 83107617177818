export interface Role {
  name: string;
}

export interface GetMeResponse {
  email: string;
  roles?: Role[];
  permissions?: string[];
}
export const defaultGetMeResponse = {
  email: '',
  roles: undefined,
  permissions: undefined,
};

export interface AuthResponse {
  nickname?: string;
  name?: string;
  picture?: string;
  updated_at?: Date | string;
  email?: string;
  email_verified?: boolean;
  sub?: string;
}
export const DefaultAuthResponse = {
  nickname: '',
  name: '',
  picture: '',
  updated_at: '',
  email: '',
  email_verified: false,
  sub: '',
};

import { Button as AntButton, ButtonProps } from 'antd';

export interface CustomButtonProps extends ButtonProps {
  buttonType: 'primary' | 'secondary' | 'light' | 'textonly';
}

export default function Button({ buttonType, ...props }: CustomButtonProps) {
  let className = '';
  switch (buttonType) {
    case 'primary':
      className = '!bg-base-3 !text-base-2 hover:enabled:!bg-gray-5 disabled:opacity-40 hover:!border-transparent';
      break;
    case 'secondary':
      className =
        '!bg-gray-6 !text-base-2 hover:enabled:!bg-base-2 hover:enabled:!text-base-3 hover:enabled:border-base-3 disabled:opacity-40';
      break;
    case 'light':
      className =
        '!bg-transparent !text-base-3 border-base-3 hover:enabled:!text-gray-5 hover:enabled:border-gray-5 disabled:opacity-40';
      break;
    case 'textonly':
      className =
        '!bg-transparent !text-base-3 border-base-3 hover:enabled:!text-gray-5 hover:enabled:border-transparent !border-none disabled:opacity-40';
      break;

    default:
      break;
  }
  return (
    <AntButton
      {...props}
      className={`border border-solid h-auto flex font-bold leading-[24px]
      items-center justify-center tracking-[1px] cursor-pointer ${className} ${props.className}`}></AntButton>
  );
}

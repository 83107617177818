import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

export const LoginLink = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const redirectLoginHandler = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): Promise<void> => {
    e.preventDefault();
    localStorage.setItem('APP_BACK_URL', location.pathname);
    await loginWithRedirect();
  };

  return (
    <>
      <p className='text-[10px] xl:text-base'>
        <span
          className='transition-colors cursor-pointer underline text-gray-7 font-bold hover:text-gray-5'
          onClick={redirectLoginHandler}>
          Please login
        </span>
        &nbsp;for more details
      </p>
    </>
  );
};

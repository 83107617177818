import dayjs from 'dayjs';

export const generateStatus = (amountDue: number | undefined | null, dueDate: string | undefined | null) => {
  let statusRs = '';
  let roundAmtDue: number | undefined | null;
  if (amountDue) {
    roundAmtDue = Math.round(amountDue * 100) / 100;
  }
  if (dayjs(dueDate).isBefore(dayjs().endOf('d'))) {
    if (!roundAmtDue) {
      statusRs = 'Paid';
    } else if (roundAmtDue > 0) {
      statusRs = 'Is Now Due';
    }
  } else {
    statusRs = 'Upcoming';
  }
  return dueDate ? statusRs : '';
};

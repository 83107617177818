// import { useAuth0 } from '@auth0/auth0-react';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { getEnvironment } from 'utils/getEnvironment';
declare module 'axios' {
  interface AxiosInstance {
    setToken(token: string): void;
    getToken(): Promise<string>;
  }
}
let store: ToolkitStore;

export const injectStore = (_store: ToolkitStore) => {
  store = _store;
};
const api = axios.create({
  baseURL: getEnvironment('REACT_APP_API_DOMAIN'),
  timeout: 60000,
});
console.log('process.env.REACT_APP_API_DOMAIN: ' + process.env.REACT_APP_API_DOMAIN);

api.setToken = (token) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

api.getToken = () => {
  // TODO
  return Promise.resolve('');
};

api.interceptors.request.use((config) => {
  config.headers = config.headers ?? {};
  config.headers.authorization = `Bearer ${store.getState().auth.accessToken}`;
  config.headers['x-api-version'] = config.headers['x-api-version'] ?? '2.0';
  return config;
});

// Status codes
api.interceptors.response.use(
  (response) => {
    // Any status code from range of 2xx
    // Do something with response data
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        // Do something, call refreshToken() request for example;
        // return a request
        // TODO
        originalConfig._retry = true;
        console.log('TODO: Logout and dispatch action to go home page');
        const { logout } = useAuth0();
        logout();
      } else if (error.response.status === 500 && !originalConfig._retry) {
        // Do something, call refreshToken() request for example;
        // return a request
        // TODO
        originalConfig._retry = true;
        console.log('TODO: Show internal error');
      }
    }

    return await Promise.reject(error);
  },
);

export default api;

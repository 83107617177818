import { selectOrderLine } from 'features/order/Order.slice';
import { toShortString } from 'helpers/dateFormats';
import moneyFormat from 'helpers/moneyFormat';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'shared/components/Button/Button';
import MobileTable from 'shared/components/MobileTable/MobileTable';
import PaymentModal from '../Payment.modal';
import { selectPaymentMades } from 'features/payment/Payment.slice';
import { ColumnsType } from 'antd/es/table';
import { ReactComponent as EyeIcon } from 'shared/icons/eye.svg';
import { PaymentMade } from 'shared/models/payment-made.model';
import { PaymentMethodTransform } from 'helpers/methodTransform';
import { useParams } from 'react-router';
import { getDocumentAndPaymentMadeFile } from 'features/document/Document.api';
import { isSafari, isMobile } from 'react-device-detect';
import isPayable from 'utils/isPayable';
import isRtoOrder from 'utils/isRtoOrder';

function PaymentMadeMobile() {
  const orderLineState = useSelector(selectOrderLine);
  const downloadElement = useRef<HTMLAnchorElement>(null);
  const downloadReceipt = async (paymentMade: PaymentMade) => {
    if (!paymentMade.recieptFileId) {
      return false;
    }
    if (downloadElement.current !== null) {
      const paymentMadeDetail = await getDocumentAndPaymentMadeFile(paymentMade.recieptFileId);
      const url = window.URL.createObjectURL(
        new Blob([paymentMadeDetail], { type: paymentMadeDetail?.type ?? 'application/pdf' }),
      );
      downloadElement.current.target = '_blank';
      if (isSafari && isMobile) {
        downloadElement.current.setAttribute('download', `PaymentReceipt_${paymentMade.recieptFileId}`);
      }
      downloadElement.current.href = url;
      downloadElement.current.click();
    }
  };
  const classHeader = 'px-5 py-5 text-xs';
  const classElement = 'text-xs h-full';
  const classPaddingElement = 'px-5 py-4';
  const arrColumnType: ColumnsType<PaymentMade> = [
    {
      title: () => {
        return (
          <div
            className={`text-left text-base-3 bg-base-1 rounded-tr-lg shadow-[10px_10px_10px_0_rgba(0,0,0,0.2)] ${classHeader}`}>
            Payment Method & Date
          </div>
        );
      },
      fixed: 'left',
      width: 140,
      render: (text, record, index) => {
        if (index === paymentMades.length - 1) {
          return (
            <>
              <div className={`bg-base-1 rounded-br-lg shadow-[10px_10px_10px_0_rgba(0,0,0,0.2)] ${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>
                  <p>{PaymentMethodTransform(record.method)}</p>
                  {record.paymentDate ? toShortString(record.paymentDate) : '-'}
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`bg-base-1 shadow-[10px_10px_10px_0_rgba(0,0,0,0.2)] ${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>
                  <p>{PaymentMethodTransform(record.method)}</p>
                  {record.paymentDate ? toShortString(record.paymentDate) : '-'}
                </div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-right text-base-3`}>Ref Number</div>;
      },
      width: 160,
      dataIndex: 'refNumber',
      key: 'refNumber',
      render: (text, record, index) => {
        if (index === paymentMades.length - 1) {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>{text ? text : '-'}</div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>{text ? text : '-'}</div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-left text-base-3`}>Comments</div>;
      },
      width: 160,
      dataIndex: 'comment',
      key: 'comment',
      render: (text, record, index) => {
        if (index === paymentMades.length - 1) {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>{text ? text : '-'}</div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>{text ? text : '-'}</div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-right text-base-3`}>Amount Paid</div>;
      },
      width: 160,
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record, index) => {
        if (index === paymentMades.length - 1) {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>
                  {record.amount ? moneyFormat(record.amount) : '-'}
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>
                  {record.amount ? moneyFormat(record.amount) : '-'}
                </div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-right text-base-3`}>Receipt</div>;
      },
      width: 160,
      dataIndex: 'reciept',
      key: 'reciept',
      render: (text, record, index) => {
        if (!record.recieptFileId) {
          return false;
        }
        if (index === paymentMades.length - 1) {
          return (
            <>
              <div className={'absolute top-[12px] right-[30px]'}>
                <EyeIcon
                  className='fill-gray-7 hover:fill-gray-5 transition-all hover:cursor-pointer align-middle'
                  onClick={(e) => downloadReceipt(record)}
                />
                <a ref={downloadElement}></a>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={'absolute top-[12px] right-[30px]'}>
                <EyeIcon
                  className='fill-gray-7 hover:fill-gray-5 transition-all hover:cursor-pointer align-middle'
                  onClick={(e) => downloadReceipt(record)}
                />
                <a ref={downloadElement}></a>
              </div>
            </>
          );
        }
      },
    },
  ];
  const [paymentModelKey, setPaymentModelKey] = useState(new Date().getTime());
  const { resourceToken = '' } = useParams();
  const [open, setOpen] = useState(false);
  const OrderInfo = useSelector(selectOrderLine);
  useEffect(() => {
    if (resourceToken) {
      setPaymentModelKey(new Date().getTime());
    }
  }, [resourceToken]);
  const paymentMades = useSelector(selectPaymentMades);
  return (
    <>
      {/*  Table */}
      <MobileTable dataSource={paymentMades} columns={arrColumnType} />
      <div className='flex space-x-[10%] mt-[30px] px-[10px] mb-[25px] justify-center'>
        <div className='bg-base-1 px-[18px] py-[15px] rounded-[15px] w-[165px]'>
          <div>
            <div className='text-xs text-base-3 font-medium'>Total Paid</div>
            <div className='text-xl text-gray-7 font-medium'>
              {moneyFormat(paymentMades.reduce((a, b) => a + b.amount, 0))}
            </div>
          </div>
        </div>
        {/* Payment Button */}
        <div className='w-2/5 max-w-[145px] flex mt-5'>
          {isPayable(orderLineState) && (
            <Button
              disabled={orderLineState.currentAmountDue === 0 && isRtoOrder(orderLineState)}
              buttonType='primary'
              className='px-[30px] py-[6px] rounded-[30px] tracking-[1px] mb-[25px] w-full text-[10px]'
              onClick={() => {
                setPaymentModelKey(new Date().getTime());
                setOpen(true);
              }}>
              MAKE A PAYMENT
            </Button>
          )}

          <PaymentModal
            open={open}
            key={paymentModelKey}
            setOpen={setOpen}
            amountDue={OrderInfo.currentAmountDue ?? 0}></PaymentModal>
        </div>
      </div>
    </>
  );
}

export default PaymentMadeMobile;

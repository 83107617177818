import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
// import { defaultDocumentDetail, DocumentDetail } from 'shared/models/document-detail.model';
import { Document } from 'shared/models/document.model';
import { getDocuments } from './Document.api';

interface DocumentState {
  documentList: Document[];
  // documentDetail: DocumentDetail;
  status: 'idle' | 'loading' | 'failed' | 'loaded';
  errorMessage?: string;
}

const initialState: DocumentState = {
  documentList: [],
  // documentDetail: defaultDocumentDetail,
  status: 'idle',
  errorMessage: undefined,
};

export const fetchDocuments = createAsyncThunk(
  'document/fetchDocuments',
  async ({
    resourceToken,
    showOnlyRequiredSignatures,
  }: {
    resourceToken: string;
    showOnlyRequiredSignatures?: boolean;
  }) => {
    const response = await getDocuments(resourceToken, showOnlyRequiredSignatures);
    return response;
  },
);

const groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const sortAlphabetically = (arr: Document[]) => {
  arr.sort(function (a, b) {
    if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
      return -1;
    }
    if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  });
  return arr;
};
export const documentSlice = createSlice({
  name: 'payment',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.errorMessage = undefined;
        let arrDocumentList: Document[] = [];
        const arrResult = groupBy(action.payload, 'templateId');
        // console.log(arrResult);
        for (const att in arrResult) {
          const highestVersion: Document = arrResult[att].sort(
            (a: { version: number }, b: { version: number }) => b.version - a.version,
          )[0];
          if (highestVersion.availableOnCP) {
            arrDocumentList.push(highestVersion);
          }
        }
        arrDocumentList = sortAlphabetically(arrDocumentList);

        state.documentList = arrDocumentList;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
      });
  },
});

export const selectDocuments = (state: RootState) => state.document.documentList;
export default documentSlice.reducer;

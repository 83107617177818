import { Spin } from 'antd';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  /* This renders the buttons above... Edit me! */
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  text-align: center;

  .ant-spin {
    position: absolute;
    top: 50%;
  }

}`;
export const Loading = () => {
  return (
    <LoadingContainer>
      <Spin></Spin>
    </LoadingContainer>
  );
};

import environment from 'app/configs/environment';
import api from 'helpers/api';
import { Document } from 'shared/models/document.model';
const { API_DOMAIN: apiDomain } = environment;

export const getDocuments = async (resourceToken: string, required?: boolean) => {
  const result = await api.get<Document[]>(`${apiDomain}/api/documents/order-lines?resource=${resourceToken}`, {
    params: {
      ...(required === true ? { isRequiredSignature: required } : {}),
    },
  });
  return result.data;
};

export const getDocumentAndPaymentMadeFile = async (documentId: string) => {
  const result = await api.get<Blob>(`${apiDomain}/api/documents/${documentId}/download`, {
    method: 'GET',
    responseType: 'blob',
  });
  return result.data;
};

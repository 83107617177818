import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { PaymentMade } from 'shared/models/payment-made.model';
import { PaymentSchedule } from 'shared/models/payment-schedule.model';
import { chargePayment, getPaymentMades, getPaymentSchedules } from './Payment.api';
import { CreatePayment, PaymentResponse } from 'shared/models/payments.model';

export interface Payment {
  paymentSchedules: PaymentSchedule[];
  paymentMades: PaymentMade[];
  status: 'idle' | 'loading' | 'failed' | 'loaded';
  errorMessage?: string;
}
const initialState: Payment = {
  paymentMades: [],
  paymentSchedules: [],
  status: 'idle',
  errorMessage: undefined,
};

export const fetchPaymentShedules = createAsyncThunk(
  'payment/fetchPaymentSchedules',
  async ({ resourceToken }: { resourceToken: string }) => {
    const response = await getPaymentSchedules(resourceToken);
    return response;
  },
);

export const fetchPaymentMades = createAsyncThunk(
  'payment/fetchPaymentMades',
  async ({ resourceToken }: { resourceToken: string }) => {
    const response = await getPaymentMades(resourceToken);
    return response;
  },
);

export const makePayment = createAsyncThunk<
  PaymentResponse,
  CreatePayment,
  {
    state: RootState;
  }
>('payment/make', async (createPayment: CreatePayment, thunkAPI): Promise<PaymentResponse> => {
  try {
    const state = thunkAPI.getState();
    const orderState = state.order;
    const configurationId = orderState.orderLine.configurationId ?? '1';

    if (configurationId) {
      createPayment.logikId = configurationId;
      createPayment.masterId = configurationId;
      const response = await chargePayment(createPayment);
      return response;
    } else {
      throw thunkAPI.rejectWithValue('Required Configuration Id');
    }
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    throw thunkAPI.rejectWithValue(err);
  }
});

const SortArrByDate = (arr: PaymentMade[]) => {
  return arr.sort(function (a, b) {
    return Date.parse(b.paymentDate) - Date.parse(a.paymentDate);
  });
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentShedules.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaymentShedules.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.errorMessage = undefined;
        state.paymentSchedules = action.payload;
      })
      .addCase(fetchPaymentShedules.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
      })
      .addCase(fetchPaymentMades.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaymentMades.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.errorMessage = undefined;
        const arrResult = SortArrByDate(action.payload);
        state.paymentMades = arrResult;
      })
      .addCase(fetchPaymentMades.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
      });
  },
});

export const selectPaymentSchedules = (state: RootState) => state.payment.paymentSchedules;
export const selectPaymentMades = (state: RootState) => state.payment.paymentMades;

export default paymentSlice.reducer;

export interface Configuration3d {
  configOptions: string;
}

export interface PurchaseInfo {
  subTotal?: number;
  totalOrder?: number;
  salesTax?: number;
}

export const defaultPurchaseInfo: PurchaseInfo = {
  subTotal: undefined,
  totalOrder: undefined,
  salesTax: undefined,
};

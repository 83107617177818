import { selectOrderLine } from 'features/order/Order.slice';
import { toShortString } from 'helpers/dateFormats';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moneyFormat from 'helpers/moneyFormat';
import Button from 'shared/components/Button/Button';
import PaymentModal from 'features/payment/Payment.modal';
import { selectPaymentSchedules } from 'features/payment/Payment.slice';
import { PaymentSchedule as PaymentScheduleModel } from 'shared/models/payment-schedule.model';
import { ColumnsType } from 'antd/es/table';
import { TableDesktopWrraper } from '../Payment.styles';
import { Table } from 'antd';
import { generateStatus } from 'helpers/generateStatus';
// import { PaymentSchedule as PaymentScheduleModel } from 'shared/models/payment-schedule.model';
import { useParams } from 'react-router';
import isPayable from 'utils/isPayable';
import isRtoOrder from 'utils/isRtoOrder';
// import { PaymentSchedule as PaymentScheduleModel } from 'shared/models/payment-schedule.model';
function PaymentSchedule() {
  const orderLineState = useSelector(selectOrderLine);
  const [open, setOpen] = useState(false);
  const OrderInfo = useSelector(selectOrderLine);
  const { resourceToken = '' } = useParams();
  const [paymentModelKey, setPaymentModelKey] = useState(new Date().getTime());

  useEffect(() => {
    if (resourceToken) {
      setPaymentModelKey(new Date().getTime());
    }
  }, [resourceToken]);

  const statusTransform = (amountDue: number | undefined | null, dueDate: string) => {
    const status = generateStatus(amountDue, dueDate);
    return (
      <div className=' w-max'>
        {status}
        {status !== 'Paid' && <p className='text-sm text-gray-9'>{`Due ${toShortString(dueDate)}`}</p>}
      </div>
    );
  };
  const columsTable: ColumnsType<PaymentScheduleModel> = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render(value, record, index) {
        return record.paymentDueDate ? statusTransform(record.amountDue ?? 0, record.paymentDueDate.toString()) : '';
      },
    },
    {
      title: 'Description',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: <div className='text-right'>Scheduled Amount</div>,
      dataIndex: 'amount',
      key: 'amount',
      width: '150px',
      render: (value) => <div className='text-right'>{moneyFormat(value)}</div>,
    },
    {
      title: <div className='text-right'>Payments Applied</div>,
      dataIndex: 'appliedAmount',
      key: 'appliedAmount',
      width: '150px',

      render: (value) => <div className='text-right'>{moneyFormat(value)}</div>,
    },
    {
      title: <div className='text-right'>Balance</div>,
      dataIndex: 'balance',
      key: 'balance',
      width: '160px',
      render: (value) => (
        <div className='text-right'>{value !== undefined && value !== null ? moneyFormat(value) : '–'}</div>
      ),
    },
    {
      title: <div className='text-right'>Current Amount Due</div>,
      dataIndex: 'amountDue',
      key: 'amountDue',
      render: (value) => (
        <div className='text-right'>{value !== undefined && value !== null ? moneyFormat(value) : '–'}</div>
      ),
    },
  ];
  const PaymentSchedules = useSelector(selectPaymentSchedules);

  return (
    <>
      {/*  Table */}
      <TableDesktopWrraper>
        <Table
          rowKey={'id'}
          columns={columsTable}
          dataSource={PaymentSchedules}
          pagination={false}
          summary={() => (
            <>
              <Table.Summary.Row className='font-bold text-gray-7 bg-gray-8'>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Totals</Table.Summary.Cell>
                <Table.Summary.Cell index={3} align='right'>
                  <div>{moneyFormat(PaymentSchedules.reduce((a, b) => a + b.amount, 0))} </div>
                  <div className='text-sm font-normal italic text-gray-9'>Your Project total</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align='right'>
                  <div>{moneyFormat(PaymentSchedules.reduce((a, b) => a + b.appliedAmount, 0))} </div>
                  <div className='text-sm font-normal italic text-gray-9 '>How we applied your payments</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align='right'>
                  <div>{moneyFormat(PaymentSchedules.reduce((a, b) => (b.balance ? a + b.balance : a), 0))}</div>
                  <div className='text-sm font-normal italic text-gray-9'>Remaining Balance on your Project</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align='right'>
                  <div>{moneyFormat(PaymentSchedules.reduce((a, b) => (b.amountDue ? a + b.amountDue : a), 0))}</div>
                  <div className='text-sm font-normal italic text-gray-9'>This is the total currently due</div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )}
        />
      </TableDesktopWrraper>

      {/* Payment Button */}
      <div className='w-full flex items-center justify-center mt-5'>
        {isPayable(orderLineState) && (
          <Button
            disabled={orderLineState.currentAmountDue === 0 && isRtoOrder(orderLineState)}
            buttonType='primary'
            className='ml-[102px] px-[30px] py-[13px] rounded-[30px]'
            onClick={() => {
              setPaymentModelKey(new Date().getTime());
              setOpen(true);
            }}>
            MAKE A PAYMENT
          </Button>
        )}

        <PaymentModal
          open={open}
          key={paymentModelKey}
          setOpen={setOpen}
          amountDue={OrderInfo.currentAmountDue ?? 0}></PaymentModal>
      </div>
    </>
  );
}

export default PaymentSchedule;

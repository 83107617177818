import { TabsProps } from 'antd';
import { fetchDocuments } from 'features/document/Document.slice';
import PaymentSchedule from 'features/payment/PaymentSchedule/PaymentSchedule.component';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppDispatch } from 'app/store';
import Tabs from 'shared/components/Tabs/Tabs';
import { Documents } from 'features/document/Documents.component';
import { fetchPaymentMades, fetchPaymentShedules } from 'features/payment/Payment.slice';
import PaymentMade from 'features/payment/PaymentMade/PaymentMade.component';
import PurchaseSummary from './PurchaseSummary/PurchaseSummary.component';
import { selectIs3dOrderLine } from '../Order.slice';

const TabContainer = styled.div`
  .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-nav {
    margin: 0px;
  }
  .ant-tabs-tab {
    padding: 20px 0;
  }
  .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-between;
    & > .ant-tabs-tab {
      flex: 1;
      & > .ant-tabs-tab-btn {
        width: 100%;
      }
    }
  }

  /* make content of tab fill 100% width */
  .tabContentWrapper > div {
    max-width: 100%;
  }
}`;

/**
 * This makes sure error response does exist, and return its statusCode.
 */
// export const hasStatusCode = (errorResponse: ErrorResponse | undefined): number | boolean => {
//   if (errorResponse === undefined || errorResponse === null) return false;
//   return errorResponse?.statusCode;
// };

export const OrderDetail = ({ show: isAuthenticated }: { show: boolean }): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const { resourceToken = '' } = useParams();
  const is3dOrderLine = useSelector(selectIs3dOrderLine);

  useEffect(() => {
    // if (!key1 || !key2 || !key3) return;
    const Params = { resourceToken };
    dispatch(fetchPaymentShedules(Params));
    dispatch(fetchPaymentMades(Params));
    dispatch(fetchDocuments(Params));
  }, [dispatch, resourceToken]);

  const detailTabStyle = {
    padding: '10px 10px',
    // marginBottom: 0,
  };

  const [activeTab, setActiveTab] = useState('1');
  const onTabChange = (e: string) => {
    setActiveTab(e);
  };
  const [detailTabs, setDetailTabs] = useState<TabsProps['items']>([]);
  const fixedTabs = [
    {
      key: '1',
      label: (
        <h4
          className={`${
            activeTab === '1' ? 'text-gray-7 ' : 'text-base-3'
          } text-lg text-center leading-[130%] font-medium`}>
          Payment Schedule
        </h4>
      ),
      children: (
        <div className='tabContentWrapper'>
          <PaymentSchedule />
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <h4
          className={`${
            activeTab === '2' ? 'text-gray-7 ' : 'text-base-3'
          } text-lg text-center leading-[130%] font-medium`}>
          Payments Made
        </h4>
      ),
      children: (
        <div className='tabContentWrapper'>
          <PaymentMade />
        </div>
      ),
    },
  ];
  const documentTab = [
    {
      key: '3',
      label: (
        <h4
          className={`${
            activeTab === '3' ? 'text-gray-7 ' : 'text-base-3'
          } text-lg text-center leading-[130%] font-medium`}>
          Documents
        </h4>
      ),
      children: (
        <div className='tabContentWrapper'>
          <Documents />
        </div>
      ),
    },
  ];

  useEffect(() => {
    // Do not init with 2 tabs, instead decide 2 or 3 tabs right after condition checking
    // This makes sure the UI is consistent.
    setDetailTabs([...fixedTabs, ...(is3dOrderLine ? documentTab : [])]);
  }, [is3dOrderLine]);

  return (
    <>
      {/* Because there are 2 components, checking if order configs finished fetching will prevent 2 components glitching */}
      {isAuthenticated && (
        <div className='flex flex-row gap-[40px] mt-10 max-w-[1422px] w-full px-4'>
          <div className='flex-[0_0_460px] h-fit rounded-[15px] bg-gray-8 px-[20px] py-[30px]'>
            <PurchaseSummary />
          </div>
          <div className='flex-1 pb-[26px] h-fit min-h-[150px] rounded-[15px] bg-gray-8 overflow-hidden'>
            <TabContainer>
              <Tabs
                onChange={onTabChange}
                items={detailTabs}
                centered={true}
                defaultActiveKey='1'
                tabBarGutter={0}
                tabBarStyle={detailTabStyle}></Tabs>
            </TabContainer>
          </div>
        </div>
      )}
    </>
  );
};

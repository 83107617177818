import api from 'helpers/api';
import environment from 'app/configs/environment';
import { PaymentSchedule } from 'shared/models/payment-schedule.model';
import { CheckPaymentMade, PaymentMade } from 'shared/models/payment-made.model';
import { CreatePayment, PaymentResponse, Merchant } from '../../shared/models/payments.model';
const apiDomain = environment.API_DOMAIN;
export async function getPaymentSchedules(resourceToken: string) {
  const result = await api.get<PaymentSchedule[]>(
    `${apiDomain}/api/payments/order-lines/schedule?resource=${resourceToken}`,
  );
  return result.data;
}
export async function getPaymentMades(resourceToken: string) {
  const result = await api.get<PaymentMade[]>(`${apiDomain}/api/payments/order-lines/made?resource=${resourceToken}`);
  return result.data;
}

export async function getMerchants(siteId: string) {
  const result = await api.get<Merchant>(`${apiDomain}/api/merchants/sites/${siteId}`);
  return result.data;
}

export async function chargePayment(payment: CreatePayment) {
  const result = await api.post<PaymentResponse>(`${apiDomain}/api/payments/make-payment`, payment);
  return result.data;
}

export async function checkPaymentByRefId(refId: string) {
  const result = await api.get<CheckPaymentMade>(`${apiDomain}/api/payments/check-payment/by-ref-id/${refId}`);
  return result.data;
}

// export const getPaymentMadeDetail = async (documentId: string) => {
//   const result = await api.get<any>(`${apiDomain}/api/documents/file-details/${documentId}`);
//   return result.data;
// };

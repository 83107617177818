import styled from 'styled-components';
import { ReactComponent as SadFaceIcon } from '../../../shared/icons/sadface.svg';
const SadFaceIconWraper = styled(SadFaceIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const NotFoundContent = styled.div`
  -webkit-text-stroke: 1px var(--gray-6);
`;
export function NotFound() {
  return (
    <div className='flex justify-center items-center h-[calc(100vh-80px)] xl:h-[calc(100vh-145px)] bg-base-2'>
      <div className='flex'>
        <div className='relative w-44 h-44 xl:w-[424px] xl:h-[424px] bg-base-1 rounded-full'>
          <SadFaceIconWraper className='w-20 xl:w-auto' />
        </div>
        <div className='-ml-[30px] xl:-ml-[60px] h-[358px] z-10 my-auto'>
          <NotFoundContent className='font-bold text-[88px] xl:text-[200px] text-center text-transparent'>
            404
          </NotFoundContent>
          <div className=' max-w-[215px] xl:max-w-[420px]'>
            <h1 className='text-gray-7 text-2xl xl:text-4xl font-bold'>Oops! Page not be found</h1>
            <p className='mt-[10px] text-base-3 tracking-[0.16px] leading-[130%] text-xs xl:text-base'>
              Sorry, but the page you are looking for does not exist, have been removed, name changed or is temporarily
              unavailable
            </p>
            {/* <div className='mt-[15px]'>
              <Link className=' text-gray-7' to={'/'}>
                Go back to the main page
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import environment from 'app/configs/environment';
import api from 'helpers/api';
import { ResourceTokenRequest } from 'shared/models/resource-token.request.model';
import { ResourceTokenResponse } from 'shared/models/resource-token.response.model';

const apiDomain = environment.API_DOMAIN;
export async function getResource(resourceToken: string) {
  return await new Promise<ResourceTokenResponse>((resolve, reject) => {
    api
      .get(`${apiDomain}/api/resource/${resourceToken}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function createResourceToken(resouce: ResourceTokenRequest) {
  return await new Promise<ResourceTokenResponse>((resolve, reject) => {
    api
      .post(`${apiDomain}/api/resource/token/orderlines`, resouce)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

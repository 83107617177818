import { Checkbox } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MobileTable from 'shared/components/MobileTable/MobileTable';
import { AppDispatch } from '../../../app/store';
import { Document } from '../../../shared/models/document.model';
import { fetchDocuments, selectDocuments } from '../Document.slice';
import { ReactComponent as EyeIcon } from 'shared/icons/eye.svg';
import { ReactComponent as DownloadIcon } from 'shared/icons/download-icon.svg';
import { ReactComponent as SignIcon } from 'shared/icons/sign-icon.svg';
import { useParams } from 'react-router-dom';
import { getDocumentAndPaymentMadeFile } from '../Document.api';
import { isSafari, isMobile } from 'react-device-detect';

export enum BUTTON_ACTION_TYPE {
  VIEW,
  DOWNLOAD,
  SIGN,
}

const transformSigningStatus = (signStatus: string, signingRequired: boolean): string => {
  switch (signStatus) {
    case 'NOT_SIGNED': {
      return signingRequired ? 'Required' : 'Not Required';
    }
    case 'SIGNED':
      return 'Complete';
    case 'PROCESSING':
      return 'In Progress'; // TODO: might change in the future
    default:
      return '-';
  }
};
const RenderActions = (document: Document): JSX.Element => {
  const { signStatus, refId, signFileUrl, requiredSignature: signingRequired } = document;
  const downloadElement = useRef<HTMLAnchorElement>(null);
  const handleButton = async (typeButton: BUTTON_ACTION_TYPE) => {
    switch (typeButton) {
      case BUTTON_ACTION_TYPE.VIEW: {
        if (downloadElement.current !== null) {
          const documentDetail = await getDocumentAndPaymentMadeFile(refId);
          const url = window.URL.createObjectURL(
            new Blob([documentDetail], { type: documentDetail?.type ?? 'application/pdf' }),
          );
          downloadElement.current.target = '_blank';
          if (isSafari && isMobile) {
            downloadElement.current.setAttribute('download', `${document.name}`);
          }
          downloadElement.current.href = url;
          downloadElement.current.click();
        }
        return;
      }
      case BUTTON_ACTION_TYPE.DOWNLOAD: {
        if (downloadElement.current !== null) {
          const documentDetail = await getDocumentAndPaymentMadeFile(refId);
          const url = window.URL.createObjectURL(
            new Blob([documentDetail], { type: documentDetail?.type ?? 'application/pdf' }),
          );
          downloadElement.current.target = '_blank';
          downloadElement.current.setAttribute('download', `${document.name}`);
          downloadElement.current.href = url;
          downloadElement.current.click();
        }
        return;
      }
      case BUTTON_ACTION_TYPE.SIGN: {
        if (downloadElement.current !== null) {
          downloadElement.current.target = '_blank';
          downloadElement.current.href = document.signFileUrl;
          downloadElement.current.click();
        }
        return;
      }
    }
  };

  return (
    <>
      <span className='pr-[7px]'>
        <EyeIcon
          className='fill-gray-7 hover:fill-gray-5 transition-all hover:cursor-pointer align-middle'
          onClick={(e) => handleButton(BUTTON_ACTION_TYPE.VIEW)}
        />
      </span>

      <span className='px-[7px]'>
        <DownloadIcon
          className='fill-gray-7 hover:fill-gray-5 transition-all hover:cursor-pointer align-middle'
          onClick={(e) => handleButton(BUTTON_ACTION_TYPE.DOWNLOAD)}
        />
      </span>

      {signFileUrl !== null &&
      signFileUrl !== undefined &&
      signFileUrl !== '' &&
      signingRequired === true &&
      signStatus === 'NOT_SIGNED' ? (
        <span className='px-[7px]'>
          <SignIcon
            className='fill-gray-7 hover:fill-gray-5 transition-all hover:cursor-pointer align-middle'
            onClick={(e) => handleButton(BUTTON_ACTION_TYPE.SIGN)}
          />
        </span>
      ) : null}
      <a ref={downloadElement}></a>
    </>
  );
};

function PaymentDocumentMobile() {
  const classHeader = 'px-5 py-5 text-xs';
  const classElement = 'text-xs h-full';
  const classPaddingElement = 'px-5 py-4';
  const arrColumnType: ColumnsType<Document> = [
    {
      title: () => {
        return (
          <div
            className={`text-left text-base-3 bg-base-1 rounded-tr-lg shadow-[10px_10px_10px_0_rgba(0,0,0,0.2)] ${classHeader}`}>
            Document Name
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 140,
      render: (text, record, index) => {
        if (index === documentList.length - 1) {
          return (
            <>
              <div className={`bg-base-1 rounded-br-lg shadow-[10px_10px_10px_0_rgba(0,0,0,0.2)] ${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>{text ? text : '-'}</div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`bg-base-1 shadow-[10px_10px_10px_0_rgba(0,0,0,0.2)] ${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>{text ? text : '-'}</div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-right text-base-3`}>Version</div>;
      },
      width: 160,
      dataIndex: 'version',
      key: 'version',
      render: (text, record, index) => {
        if (index === documentList.length - 1) {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>{text ? text : '-'}</div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-right text-base-3 ${classPaddingElement}`}>{text ? text : '-'}</div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-left text-base-3`}>Signature</div>;
      },
      width: 160,
      key: 'signature',
      render: (text, record, index) => {
        if (index === documentList.length - 1) {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>
                  {record.signStatus ? transformSigningStatus(record.signStatus, record.requiredSignature) : '-'}
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className={`${classElement}`}>
                <div className={`text-left text-base-3 ${classPaddingElement}`}>
                  {record.signStatus ? transformSigningStatus(record.signStatus, record.requiredSignature) : '-'}
                </div>
              </div>
            </>
          );
        }
      },
    },
    {
      title: () => {
        return <div className={`${classHeader} text-base-3`}>Action</div>;
      },
      width: 160,
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        if (index === documentList.length - 1) {
          return (
            <>
              <div className='px-5'>
                <RenderActions {...record} />
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className='px-5'>
                <RenderActions {...record} />
              </div>
            </>
          );
        }
      },
    },
  ];
  const dispatch = useDispatch<AppDispatch>();
  const { resourceToken = '' } = useParams();

  const [showOnlyRequiredSignatures, setShowOnlyRequiredSignatures] = useState(false);

  const toggleShowRequiredSignatures = () => {
    setShowOnlyRequiredSignatures(!showOnlyRequiredSignatures);
  };
  useEffect(() => {
    // if (!key1 || !key2 || !key3) return;
    const Params = { resourceToken };
    dispatch(fetchDocuments({ ...Params, showOnlyRequiredSignatures }));
  }, [dispatch, resourceToken, showOnlyRequiredSignatures]);

  const documentList = useSelector(selectDocuments);
  return (
    <>
      <div className='mb-[25px] bg-gray-8'>
        <Checkbox
          checked={showOnlyRequiredSignatures}
          onChange={toggleShowRequiredSignatures}
          className='float-right text-[10px] mt-[10px] mb-[10px] ml-[10px] !text-base-3 !font-normal'>
          Show only Signature Required
        </Checkbox>

        {/*  Table */}
        <MobileTable dataSource={documentList} columns={arrColumnType} />
      </div>
    </>
  );
}

export default PaymentDocumentMobile;

import { OrderLine } from 'shared/models/order-line.model';

/**
 * This function checks if an order has Master Id or not. Use this to render/hide specific section(s) of the app.
 * Current condition:
 * - `orderLine.configurationId` must be available.
 */
const hasMasterId = (order: OrderLine): boolean => {
  return order.configurationId != null && order.configurationId !== undefined;
};

export default hasMasterId;

import React from 'react';
import { MaskedInput, MaskedInputRef } from './MaskedInput';
import { Rule } from 'antd/es/form';
import { InputProps } from 'antd';

export const CREDIT_CARD_MASK = '0000 0000 0000 0000';

export type CreditCardInputRef = MaskedInputRef & {
  validators: Rule[];
};
export type CreditCardInputProps = InputProps & React.RefAttributes<CreditCardInputRef>;

const includeSpacesInLength = (length: number): number => length + Math.floor(length / 4);

export const CreditCardInput = React.forwardRef<Partial<CreditCardInputRef>, CreditCardInputProps>(function MoneyInput(
  // eslint-disable-next-line react/prop-types
  { onBlur, ...props }: CreditCardInputProps,
  innerRef,
): JSX.Element {
  return (
    <MaskedInput
      className='h-[42px] border-solid border-1-[#DEDEDE] rounded-[15px] leading-6 text-sm text-base-3 px-[16px] py-[9px]'
      maskOptions={{
        mask: CREDIT_CARD_MASK,
        placeholderChar: '0',
      }}
      {...props}
      minLength={props.minLength && includeSpacesInLength(props.minLength)}
      maxLength={props.maxLength && includeSpacesInLength(props.maxLength)}
    />
  );
});

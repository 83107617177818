import { useAuth0 } from '@auth0/auth0-react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LogoutButton } from '../LogoutButton/LogoutButton';
function HeaderViewDetailMobile() {
  const { resourceToken } = useParams();
  return (
    <div className='relative flex items-center justify-center pt-5 min-w-[375px]'>
      {/* <img alt='Back Icon' src='/icons/Vector.svg' /> */}
      <Link className='absolute left-[14px] flex items-center' to={`/${resourceToken}`}>
        <img alt='Back Icon' src='/icons/Vector.svg' />
        <p className='ml-[11px] font-medium text-gray-7 text-[10px]'>Back To General</p>
        {/* Back To General */}
      </Link>
      <img alt='UlRich Logo' className='mr-[2px] w-[95px] xl:w-[153px]' src='/ulrich_logo.svg' />
    </div>
  );
}
const LogoutButtonWrapper = styled(LogoutButton)`
  top: 50%;
  transform: translateY(-50%);
`;
export const Header = ({ status }: { status?: string }) => {
  const { isAuthenticated } = useAuth0();
  if (status === 'DetailMobile') {
    return (
      <>
        <HeaderViewDetailMobile></HeaderViewDetailMobile>
      </>
    );
  } else {
    return (
      <>
        <header className='bg-base-2 xl:py-5 xl:contents'>
          {/* logo */}
          <div className='flex justify-center mt-5 xl:mt-[60px] mb-5 relative'>
            {/* <HeaderView /> */}
            <img alt='UlRich Logo' className='mr-[2px] w-[95px] xl:w-[153px]' src='/ulrich_logo.svg' />
            <LogoutButtonWrapper
              buttonType='light'
              show={isAuthenticated}
              className='py-1 px-[10px] xl:py-[13px] xl:px-[30px] rounded-[30px] absolute xl:text-[16px] right-[15px] 
              tracking-normal font-medium text-[10px] xl:right-[60px]'>
              LOGOUT
            </LogoutButtonWrapper>
          </div>
          {/* This indicates app is running in DEV */}
          {process.env.NODE_ENV === 'development' && (
            <span className='fixed top-[5px] left-[5px] text-[#ff0000] bg-[#ffff00] px-[10px] py-[5px] shadow-lg shadow-black-500/50'>
              DEV
            </span>
          )}
        </header>
      </>
    );
  }
};

import environment from 'app/configs/environment';
import api from 'helpers/api';
import { GetMeResponse } from 'shared/models/get-me.response.model';

const apiDomain = environment.API_DOMAIN;
export async function getMe() {
  const result = await api.get<GetMeResponse>(`${apiDomain}/api/auth/me`, {
    headers: { 'x-api-version': '1.0' },
  });
  return result.data;
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ErrorResponse } from 'shared/models/error.response.model';
import { getResource } from './PrivateUrl.api';
import { AxiosError } from 'axios';
import { DefaultResourceTokenResponse, ResourceTokenResponse } from 'shared/models/resource-token.response.model';

export interface PrivatePath {
  resouce: ResourceTokenResponse;
  status: 'idle' | 'loading' | 'failed' | 'loaded';
  errorMessage?: string;
  errorResponse?: ErrorResponse;
}
const initialState: PrivatePath = {
  resouce: DefaultResourceTokenResponse,
  status: 'idle',
  errorMessage: undefined,
  errorResponse: undefined,
};
export const fetchResource = createAsyncThunk<
  ResourceTokenResponse,
  { resourceToken: string },
  {
    rejectValue: ErrorResponse;
  }
>('privateurl/fetchResource', async ({ resourceToken }: { resourceToken: string }, { rejectWithValue }) => {
  try {
    const data = await getResource(resourceToken);
    return data;
  } catch (_error) {
    const error = _error as AxiosError;
    const dataErrorResponse = error.response?.data as ErrorResponse;
    return rejectWithValue(dataErrorResponse);
  }
});
export const privateUrlSlice = createSlice({
  name: 'privateUrl',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchResource.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchResource.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.errorMessage = undefined;
        state.resouce = action.payload;
      })
      .addCase(fetchResource.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message;
        state.errorResponse = action.payload;
      });
  },
});

export const selectPrivateUrl = (state: RootState) => state.privateUrl;
export const selectPrivateUrlResponseError = (state: RootState) => state.privateUrl.errorResponse;

export default privateUrlSlice.reducer;

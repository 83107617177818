import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_DATE_FORMAT } from 'utils/constants';

dayjs.extend(utc);

const toShortString = (date: Date | string) => {
  const shortTime = dayjs(date).utc().format(DEFAULT_DATE_FORMAT);
  return shortTime;
};

export { toShortString };

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const LogoutHandler = () => {
  // use navigate instead of history: https://stackoverflow.com/a/70531954
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    if (localStorage.getItem('APP_BACK_URL')) {
      navigate(localStorage.getItem('APP_BACK_URL')!);
    } else {
      navigate(-2);
    }
  }, [navigate]);

  return <div></div>;
};

import { select3dOrderConfigs } from 'features/order/Order.slice';
import moneyFormat from 'helpers/moneyFormat';
import { useSelector } from 'react-redux';
import { BuildingDetails, parseBuildingDetails } from 'utils/parsers';
import { ReactComponent as CloseIcon } from 'shared/icons/close.svg';
import { useEffect, useState } from 'react';

const summaryConditions = (item: any) =>
  item.BomType === 'Sales' && (item.Extended.OrderSummary === 'true' || item.Extended.OrderSummary === true);

function AllConfigurationInfo({ onClose }: { onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void }) {
  const [pricingDetailList, setPricingDetailList] = useState<BuildingDetails[]>([]);
  const [summary, setSummary] = useState<any[]>([]);

  const orderConfigsState = useSelector(select3dOrderConfigs);

  useEffect(() => {
    setPricingDetailList(parseBuildingDetails(orderConfigsState));
    setSummary(orderConfigsState.filter(summaryConditions));
  }, [orderConfigsState]);

  return (
    <div className='h-full flex flex-col'>
      <div className='flex justify-between xl:px-[40px] px-[20px] items-center'>
        <p className='xl:text-[18px] text-[14px] my-5 xl:mt-0 xl:mb-6 text-gray-7 font-bold leading-[130%] '>
          Building Details
        </p>
        <CloseIcon className='xl:hidden fill-gray-9' onClick={onClose} />
      </div>
      {/* Pricing List */}
      <div className='xl:px-[40px] px-[20px] flex-auto flex flex-col gap-[10px] pb-[10px] overflow-y-auto xl:text-[16px] text-[12px]'>
        {pricingDetailList.map((item, index) => {
          return (
            <div key={index} className='w-[100%] flex flex-row justify-between gap-[10px] leading-[130%]'>
              <p style={{ paddingLeft: `${item.level * 20}px` }} className={`text-base-3`}>
                {item?.title ?? ''}
              </p>
              <span className={` text-gray-7 flex-none w-[90px] text-right`}>
                {moneyFormat(parseFloat(item.value))}
              </span>
            </div>
          );
        })}
      </div>

      {/* Summary */}
      <div
        className={`flex flex-col gap-[10px] bg-base-1 py-[30px] xl:px-[40px] px-[20px] rounded-tl-[15px] rounded-tr-[15px]`}>
        {summary
          .sort((a, b) => a.OrderNumber - b.OrderNumber)
          .map((item, index) => {
            return (
              <div
                key={index}
                className={`w-[100%] flex flex-row justify-between gap-[10px] leading-[130%] text-[14px] ${
                  (item.Extended.Highlight === 'true' || item.Extended.Highlight === true) && 'font-medium'
                }`}>
                <p className='text-base-3'>{item?.Description ?? ''}</p>
                <span className='text-gray-7 text-[14px] flex-none w-[90px] text-right'>
                  {moneyFormat(Number(item?.Extended?.extPrice ?? 0))}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default AllConfigurationInfo;

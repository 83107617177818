import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moneyFormat from 'helpers/moneyFormat';
import Button from 'shared/components/Button/Button';
import Drawer from 'shared/antd/components/Drawer';
import {
  select3dOrderConfigs,
  selectIs3dOrderLine,
  selectOrderConfigsErrorResponse,
  selectOrderLine,
  selectOrderPurchaseInfo,
  selectOrderPurchaseInfoErrorResponse,
} from 'features/order/Order.slice';
import AllConfigurationInfo from '../AllConfigurationInfo/AllConfigurationInfo.component';
import { ReactComponent as CloseIcon } from 'shared/icons/close.svg';
import { PurchaseInfo } from 'shared/models/configuration.response';
import { isBoolean } from 'lodash';

const purchaseSummaryConditions = (item: any) =>
  item.BomType === 'Sales' &&
  (item.Extended.PrintSpecDet === 'true' || item.Extended.PrintSpecDet === true) &&
  item.Extended.SectionSpecDet === 'Pricing';

const PurchaseSummary = ({ onClose }: { onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [purchase3dSummary, setPurchase3dSummary] = useState<any[]>();
  const [purchaseInfo, setPurchaseInfo] = useState<PurchaseInfo>();
  const [totalPaymentsApplied, setTotalPaymentsApplied] = useState<number>();
  const [remainingBalance, setRemainingBalance] = useState<number>();
  const [currentAmountDue, setCurrentAmountDue] = useState<number>();

  const openConfigInfo = () => {
    setDrawerVisible(true);
  };
  const closeConfigInfo = () => {
    setDrawerVisible(false);
  };

  const is3dOrderLine = useSelector(selectIs3dOrderLine);
  const orderConfigsState = useSelector(select3dOrderConfigs);
  const orderPurchaseInfoState = useSelector(selectOrderPurchaseInfo);
  const orderState = useSelector(selectOrderLine);

  const purchaseSummary3dErrResponse = useSelector(selectOrderConfigsErrorResponse);
  const purchaseInfoErrResponse = useSelector(selectOrderPurchaseInfoErrorResponse);

  useEffect(() => {
    if (!isBoolean(is3dOrderLine)) return;

    if (is3dOrderLine) {
      setPurchaseInfo(undefined);
      setPurchase3dSummary(orderConfigsState.filter(purchaseSummaryConditions));
    } else {
      setPurchase3dSummary(undefined);
      setPurchaseInfo(orderPurchaseInfoState);
    }
  }, [orderConfigsState, orderPurchaseInfoState, is3dOrderLine]);

  useEffect(() => {
    /* 3 "must-have" additional fields */
    setTotalPaymentsApplied(orderState.totalPayments! * -1);
    setRemainingBalance(orderState.remainingBalance);
    setCurrentAmountDue(orderState.currentAmountDue);
  }, [orderState]);

  return (
    <>
      <div className='flex flex-col justify-start gap-[10px] px-5 xl:px-0 py-[15px] xl:py-0 text-xs xl:text-base'>
        <div className='flex justify-between'>
          <h4 className='text-gray-7 xl:text-[18px] text-[14px] font-bold'>Purchase Summary</h4>
          <CloseIcon className='xl:hidden fill-gray-9' onClick={onClose} />
        </div>
        {is3dOrderLine ? (
          // 3D orderLine selected
          <>
            {
              // Make sure orderConfigs data is ready to render
              purchase3dSummary !== undefined && (
                <>
                  {purchaseSummary3dErrResponse?.statusCode !== 404 ? (
                    <>
                      {(purchase3dSummary as any[])
                        .sort((a, b) => a.OrderNumber - b.OrderNumber)
                        .map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={`w-[100%] flex flex-row justify-between leading-[130%] xl:text-[16px] text-[12px] ${
                                (item.Extended.Highlight === 'true' || item.Extended.Highlight === true) &&
                                'font-medium'
                              }`}>
                              <h6 className='text-base-3'>{item?.Description ?? ''}</h6>
                              <h6 className='text-gray-7 flex-none w-[90px] text-right'>
                                {moneyFormat(Number(item.Extended?.extPrice ?? 0))}
                              </h6>
                            </div>
                          );
                        })}
                      {/* 3 "must-have" additional fields */}
                      {/* Because parsing configs takes time, wait until finish parsing then display these fields  */}
                      {purchase3dSummary.length > 0 && (
                        <>
                          <div className='w-[100%] flex flex-row justify-between leading-[130%] text-base-3 font-medium'>
                            <h6 className='text-base-3'>Total Payments</h6>
                            <h6 className='text-gray-7 flex-none w-[90px] text-right'>
                              {moneyFormat(totalPaymentsApplied)}
                            </h6>
                          </div>
                          <div className='w-[100%] flex flex-row justify-between leading-[130%] text-base-3 font-medium'>
                            <h6 className='text-base-3'>Remaining Balance</h6>
                            <h6 className='text-gray-7 flex-none w-[90px] text-right'>
                              {moneyFormat(remainingBalance)}
                            </h6>
                          </div>
                          <div className='w-[100%] flex flex-row justify-between leading-[130%] text-base-3 font-medium'>
                            <h6 className='text-base-3'>Current Amount due</h6>
                            <h6 className='text-gray-7 flex-none w-[90px] text-right'>
                              {moneyFormat(currentAmountDue)}
                            </h6>
                          </div>
                        </>
                      )}
                      <Button
                        buttonType='light'
                        className='mt-[30px] px-[30px] py-[13px] rounded-[30px] hidden xl:block'
                        disabled={orderConfigsState.length === 0}
                        onClick={openConfigInfo}>
                        VIEW CONFIGURATION INFO
                      </Button>
                      <Drawer open={drawerVisible} onClose={closeConfigInfo} placement='left' width={460}>
                        <AllConfigurationInfo />
                      </Drawer>
                    </>
                  ) : (
                    <h6 className='text-base-3 xl:text-[18px] text-[14px]'>No data found.</h6>
                  )}
                </>
              )
            }
          </>
        ) : (
          // non-3D orderLine selected.
          <>
            {
              // Make sure purchaseInfo data is ready to render
              purchaseInfo !== undefined && (
                <>
                  {purchaseInfoErrResponse?.statusCode !== 404 ? (
                    <>
                      <div className='w-[100%] flex flex-row justify-between leading-[130%] text-base-3 font-medium'>
                        <h6 className='text-base-3'>Subtotal</h6>
                        <h6 className='text-gray-7 flex-none w-[90px] text-right'>
                          {moneyFormat(Number(purchaseInfo.subTotal))}
                        </h6>
                      </div>
                      <div className='w-[100%] flex flex-row justify-between leading-[130%] text-base-3'>
                        <h6 className='text-base-3'>Sales Tax</h6>
                        <h6 className='text-gray-7 flex-none w-[90px] text-right'>
                          {moneyFormat(Number(purchaseInfo.salesTax))}
                        </h6>
                      </div>
                      <div className='w-[100%] flex flex-row justify-between leading-[130%] text-base-3 font-medium'>
                        <h6 className='text-base-3'>Total Order</h6>
                        <h6 className='text-gray-7 flex-none w-[90px] text-right'>
                          {moneyFormat(Number(purchaseInfo.totalOrder))}
                        </h6>
                      </div>
                      {/* 3 "must-have" additional fields */}
                      <div className='w-[100%] flex flex-row justify-between leading-[130%] text-base-3 font-medium'>
                        <h6 className='text-base-3'>Total Payments</h6>
                        <h6 className='text-gray-7 flex-none w-[90px] text-right'>
                          {moneyFormat(totalPaymentsApplied)}
                        </h6>
                      </div>
                      <div className='w-[100%] flex flex-row justify-between leading-[130%] text-base-3 font-medium'>
                        <h6 className='text-base-3'>Remaining Balance</h6>
                        <h6 className='text-gray-7 flex-none w-[90px] text-right'>{moneyFormat(remainingBalance)}</h6>
                      </div>
                      <div className='w-[100%] flex flex-row justify-between leading-[130%] text-base-3 font-medium'>
                        <h6 className='text-base-3'>Current Amount due</h6>
                        <h6 className='text-gray-7 flex-none w-[90px] text-right'>{moneyFormat(currentAmountDue)}</h6>
                      </div>
                    </>
                  ) : (
                    <h6 className='text-base-3 xl:text-[18px] text-[14px]'>No data found.</h6>
                  )}
                </>
              )
            }
          </>
        )}
      </div>
    </>
  );
};

export default PurchaseSummary;

import { Checkbox, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableDesktopWrraper } from 'features/payment/Payment.styles';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from '../../app/store';
import { ReactComponent as EyeIcon } from 'shared/icons/eye.svg';
import { ReactComponent as DownloadIcon } from 'shared/icons/download-icon.svg';
import { ReactComponent as SignIcon } from 'shared/icons/sign-icon.svg';
import { Document } from '../../shared/models/document.model';
import { fetchDocuments, selectDocuments } from './Document.slice';
import { getDocumentAndPaymentMadeFile } from './Document.api';

export enum BUTTON_ACTION_TYPE {
  VIEW,
  DOWNLOAD,
  SIGN,
}

const transformSigningStatus = (signStatus: string, signingRequired: boolean): string => {
  switch (signStatus) {
    case 'NOT_SIGNED': {
      return signingRequired ? 'Required' : 'Not Required';
    }
    case 'SIGNED':
      return 'Complete';
    case 'PROCESSING':
      return 'In Progress'; // TODO: might change in the future
    default:
      return '-';
  }
};

const RenderActions = (document: Document): JSX.Element => {
  const { signStatus, refId, signFileUrl, requiredSignature: signingRequired } = document;
  const downloadElement = useRef<HTMLAnchorElement>(null);

  const handleButton = async (typeButton: BUTTON_ACTION_TYPE) => {
    switch (typeButton) {
      case BUTTON_ACTION_TYPE.VIEW: {
        if (downloadElement.current !== null) {
          const documentDetail = await getDocumentAndPaymentMadeFile(refId);
          const url = window.URL.createObjectURL(
            new Blob([documentDetail], { type: documentDetail?.type ?? 'application/pdf' }),
          );
          downloadElement.current.target = '_blank';
          downloadElement.current.href = url;
          downloadElement.current.click();
        }
        return;
      }
      case BUTTON_ACTION_TYPE.DOWNLOAD: {
        if (downloadElement.current !== null) {
          const documentDetail = await getDocumentAndPaymentMadeFile(refId);
          const url = window.URL.createObjectURL(
            new Blob([documentDetail], { type: documentDetail?.type ?? 'application/pdf' }),
          );
          downloadElement.current.target = '_blank';
          downloadElement.current.setAttribute('download', `${document.name}`);
          downloadElement.current.href = url;
          downloadElement.current.click();
        }
        return;
      }
      case BUTTON_ACTION_TYPE.SIGN: {
        if (downloadElement.current !== null) {
          downloadElement.current.target = '_blank';
          downloadElement.current.href = document.signFileUrl;
          downloadElement.current.click();
        }
        return;
      }
    }
  };

  return (
    <>
      <span className='pr-[7px]'>
        <EyeIcon
          className='fill-gray-7 hover:fill-gray-5 transition-all hover:cursor-pointer align-middle'
          onClick={(e) => handleButton(BUTTON_ACTION_TYPE.VIEW)}
        />
      </span>

      <span className='px-[7px]'>
        <DownloadIcon
          className='fill-gray-7 hover:fill-gray-5 transition-all hover:cursor-pointer align-middle'
          onClick={(e) => handleButton(BUTTON_ACTION_TYPE.DOWNLOAD)}
        />
      </span>

      {signFileUrl !== null &&
      signFileUrl !== undefined &&
      signFileUrl !== '' &&
      signingRequired === true &&
      signStatus === 'NOT_SIGNED' ? (
        <span className='px-[7px]'>
          <SignIcon
            className='fill-gray-7 hover:fill-gray-5 transition-all hover:cursor-pointer align-middle'
            onClick={(e) => handleButton(BUTTON_ACTION_TYPE.SIGN)}
          />
        </span>
      ) : null}
      <a ref={downloadElement}></a>
    </>
  );
};

export const Documents = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const { resourceToken = '' } = useParams();
  const [showOnlyRequiredSignatures, setShowOnlyRequiredSignatures] = useState(false);
  const toggleShowRequiredSignatures = () => {
    setShowOnlyRequiredSignatures(!showOnlyRequiredSignatures);
  };
  useEffect(() => {
    const Params = { resourceToken };
    dispatch(fetchDocuments({ ...Params, showOnlyRequiredSignatures }));
  }, [dispatch, resourceToken, showOnlyRequiredSignatures]);
  const columsTable: ColumnsType<Document> = [
    {
      title: 'Document Name',
      dataIndex: 'name',
      key: 'name',
      render: (value) => <div>{value}</div>,
      width: '488px',
    },
    {
      title: <div className='text-center'>Version</div>,
      dataIndex: 'version',
      key: 'version',
      render: (value) => <div className='text-center'>{value}</div>,
    },
    {
      title: 'Signature',
      key: 'Signature',
      render: (value, record) => transformSigningStatus(record.signStatus, record.requiredSignature),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => (
        <div className=''>
          <RenderActions {...record} />
        </div>
      ),
    },
  ];
  const documentsState = useSelector(selectDocuments);

  return (
    <>
      <Checkbox
        checked={showOnlyRequiredSignatures}
        onChange={toggleShowRequiredSignatures}
        className='float-right mt-[23px] mr-[23px]'>
        Show only Signature Required
      </Checkbox>
      <TableDesktopWrraper>
        <Table rowKey={'id'} columns={columsTable} dataSource={documentsState} pagination={false} />
      </TableDesktopWrraper>
    </>
  );
};

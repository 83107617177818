import { notification, Spin } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { useCallback, useState } from 'react';
import { CardPaymentForm } from './CardPaymentForm.component';
import { makePayment } from './Payment.slice';
import { CreatePayment, CreditCardPayment, TransactionStatus } from 'shared/models/payments.model';
import { selectOrderLine } from 'features/order/Order.slice';
import { useSelector } from 'react-redux';
import { OrderLine } from 'shared/models/order-line.model';
import { ErrorMessage } from 'shared/components/Form/Form';
import Modal from 'shared/antd/components/Modal';
import { selectPrivateUrl } from 'features/privateurl/PrivateUrl.slice';

export type CardPaymentModalProps = {
  setOpen: (open: boolean) => void;
  open: boolean;
  amountDue: number;
  siteId?: string;
};

const PaymentModal = ({ open, setOpen, amountDue, siteId }: CardPaymentModalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const orderLineState: OrderLine = useSelector(selectOrderLine);
  const curSiteId: string = useSelector(selectPrivateUrl).resouce.key1 ?? '';
  const onCancel = useCallback(() => {
    console.log('Cancel payment');
  }, []);

  const errPayment = useCallback((err: unknown) => {
    console.error(err);
  }, []);

  const [checkingPaymentStatus, setcheckingPaymentStatus] = useState<boolean>(false);
  const [makingPayment, setMakingPayment] = useState<boolean>(false);

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     // TODO
  //     console.log('Check payment');
  //   }, 2000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const finishPayment = useCallback(
    async (payload: CreatePayment) => {
      try {
        if (!orderLineState.configurationId || !orderLineState.configurationId) {
          errPayment?.('Required Configuration Id');
          notification.error({
            message: <ErrorMessage text={'Required Configuration Id'} />,
            duration: 0,
            placement: 'bottomLeft',
          });
        } else {
          setMakingPayment(true);
          const paymentRes = (await dispatch(makePayment(payload)).unwrap()) as CreditCardPayment;
          paymentRes.status = paymentRes.status ? paymentRes.status : TransactionStatus.Error;
          if ([TransactionStatus.Approved, TransactionStatus.HeldForReview].includes(paymentRes.status)) {
            setMakingPayment(false);
            setcheckingPaymentStatus(false);
            notification.success({
              message: 'Payment success',
              duration: 0,
              placement: 'bottomLeft',
            });
            setOpen(false);
          } else {
            setcheckingPaymentStatus(false);
            setMakingPayment(false);
            notification.error({
              message: (
                <ErrorMessage text={paymentRes.reason !== '' ? paymentRes.reason : 'Failed to process payment'} />
              ),
              duration: 0,
              placement: 'bottomLeft',
            });
          }
        }
      } catch (err) {
        errPayment?.(err);
        notification.error({
          message: <ErrorMessage text={'Failed to process payment'} />,
          duration: 0,
          placement: 'bottomLeft',
        });
      }
    },
    [dispatch, errPayment, orderLineState],
  );

  return (
    <>
      <Modal
        title={<h4 className='text-gray-7 text-lg font-bold leading-[130%] mb-[24px]'>Payment</h4>}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
        footer={null}
        closable={checkingPaymentStatus || makingPayment ? false : true}>
        <Spin
          tip={
            makingPayment
              ? "Process payment...Please don't close this screen."
              : "Check your payment status...Please don't close this screen."
          }
          spinning={checkingPaymentStatus || makingPayment}>
          <CardPaymentForm
            onFinish={finishPayment}
            onError={errPayment}
            onCancel={onCancel}
            amountDue={amountDue}
            siteId={siteId ?? curSiteId}></CardPaymentForm>
        </Spin>
      </Modal>
    </>
  );
};

export default PaymentModal;

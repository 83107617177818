export interface BuildingDetails {
  title: string;
  value: any;
  level: number;
  orderNumber: any;
  id: string;
  parentId?: string | null;
  children?: Array<BuildingDetails>;
}

const arrayToTree = (arr: Array<BuildingDetails>, parent?: string | null, sort?: any): Array<BuildingDetails> =>
  arr
    .filter((item: BuildingDetails) => item.parentId === parent)
    .map((child: any) => ({
      ...child,
      children: sort ? sort(arrayToTree(arr, child.id)) : arrayToTree(arr, child.id),
    }));

const parseBuildingDetails = (data: Array<any>): Array<BuildingDetails> => {
  const map: any = {};
  const list = data.filter((f) => {
    const extended = f.Extended;
    const orderNumber = f.OrderNumber;
    const bomType = f.BomType;
    const ok =
      orderNumber !== null &&
      orderNumber !== undefined &&
      extended &&
      bomType &&
      bomType.toUpperCase() === 'SALES' &&
      (extended.PrintPricingDet === 'true' || extended.PrintPricingDet === true) &&
      extended.SectionPricingDet &&
      (extended.OrderSummary == null || extended.OrderSummary === 'false' || extended.OrderSummary === false) &&
      extended.SectionPricingDet.toUpperCase() === 'CONFIGURATION';
    if (ok) {
      map[f.UniqueIdentifier] = f;
    }
    return ok;
  });
  const array: Array<BuildingDetails> = list.map((m) => {
    return {
      id: m.UniqueIdentifier,
      parentId: m.ParentProduct ? m.ParentProduct : null,
      title: m.Description,
      value: m.ExtPrice ? m.ExtPrice : m.Extended.extPrice ? m.Extended.extPrice : 0,
      level: m.Level,
      orderNumber: m.OrderNumber,
    };
  });
  const tree = arrayToTree(array, null, (array: Array<any>) => {
    return array.sort((a, b) => {
      if (a.orderNumber > b.orderNumber) {
        return 1;
      } else if (a.orderNumber < b.orderNumber) {
        return -1;
      } else {
        return a.title.localeCompare(b.title);
      }
    });
  });
  const rs: Array<BuildingDetails> = [];
  const flat = (rootArray: any, result: any[]) => {
    for (let index = 0; index < rootArray.length; index++) {
      const element = rootArray[index];
      result.push(element);
      if (element.children) {
        flat(element.children, result);
      }
    }
  };
  flat(tree, rs);
  return rs;
};
// console.log(parsePurchaseSummary(json));
// console.log(parseBuildingDetails(json));

export { parseBuildingDetails };

import { Input as AntInput, InputNumber as AntInputNumber, InputNumberProps } from 'antd';
import { GroupProps, InputProps, InputRef, PasswordProps } from 'antd/es/input';
import React, { Ref } from 'react';

const classNameStyle = `h-[42px] border-solid border-1-[#DEDEDE] !rounded-[15px] leading-6 text-sm text-base-3 px-[16px] py-[9px] placeholder:text-base-3`;

const inlineStyle = {
  // styles that are unable to set via Tailwind CSS class
};

const Input = React.forwardRef(({ ...props }: InputProps, ref?: Ref<InputRef>) => (
  <AntInput
    ref={ref}
    {...props}
    style={{ ...inlineStyle, ...props.style }}
    className={`${classNameStyle} ${props.className}`}
  />
));
Input.displayName = 'Input';

// FIXME: cannot hide arrow of numeric input yet, use the regular Input
const InputNumber = ({ ...props }: InputNumberProps) => (
  <AntInputNumber
    {...props}
    style={{ ...inlineStyle, ...props.style }}
    className={`${classNameStyle} ${props.className}`}
  />
);

const Password = ({ ...props }: PasswordProps) => (
  <AntInput.Password
    {...props}
    style={{ ...inlineStyle, ...props.style }}
    className={`${classNameStyle} ${props.className}`}
  />
);

const Group = ({ ...props }: GroupProps) => (
  <AntInput.Group compact style={{ ...inlineStyle, ...props.style }} {...props} className={`${props.className}`} />
);

// Input.Password = Password;
// Input.Group = Group;

const InputNamespace = Object.assign(Input, {
  Password: Password,
  Group: Group,
});

InputNumber.Group = Group; // is this correct?

export { InputNamespace as Input, InputNumber };

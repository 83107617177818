import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Outlet, Route, Routes } from 'react-router-dom';
import Footer from '../Footer/Footer';
import { Header } from '../Header/Header';

function DefaultLayout() {
  return (
    <Layout className='bg-transparent'>
      <Routes>
        <Route path=':resourceToken/:tabName' element={<Header status={'DetailMobile'} />}></Route>
        <Route path=':siteId/:orderNum/:lineId/:tabName' element={<Header status={'DetailMobile'} />}></Route>
        <Route path='*' element={<Header />} />
      </Routes>
      <Content className='min-w-[375px]'>
        <Outlet />
      </Content>
      <Footer />
    </Layout>
  );
}

export default DefaultLayout;
export function EmptyLayout() {
  return (
    <Layout>
      <Content className='min-w-[375px]'>
        <Outlet />
      </Content>
    </Layout>
  );
}

import { Form as AntForm, FormItemProps } from 'antd';

const itemClassNameStyle = ``;

const itemInlineStyle = {
  // styles that are unable to set via Tailwind CSS class
};

// FIXME: Form & item override not working yet, use Ant's!
const Form = ({ ...props }: any) => <AntForm {...props} />;
const Item = ({ ...props }: FormItemProps) => (
  <AntForm.Item
    {...props}
    style={{ ...itemInlineStyle, ...props.style }}
    className={`${itemClassNameStyle} ${props.className}`}
  />
);
Form.Item = Item;
export default Form;
//

// Customize a few stuffs for form:
interface CustomHTMLSpanProps extends React.HTMLProps<HTMLSpanElement> {
  text?: string;
}

export const FormControlLabel = ({ text, ...props }: CustomHTMLSpanProps): JSX.Element => {
  return (
    <span {...props} className={`whitespace-nowrap overflow-visible leading-[24px] text-base-3 ${props.className}`}>
      {text}
    </span>
  );
};

export const ErrorMessage = ({ text, ...props }: CustomHTMLSpanProps): JSX.Element => {
  return (
    // eslint-disable-next-line react/prop-types
    <span {...props} className={`block py-[10px] leading-[16px] text-[14px] text-[#DE5555] ${props.className}`}>
      {text ?? props.children}
    </span>
  );
};

import { Table } from 'antd';
import styled from 'styled-components';
// import type { ColumnsType } from 'antd/es/table';

// interface DataType {
//   status: React.Key;
//   Description: string;
//   ScheduledAmount: string;
//   PaymentsApplied: string;
//   Balance: string;
//   CurrAmountDue: string;
// }
/* remove css default of table antdesign */
const TableContainer = styled.div`
  .ant-table-thead > tr > th{
    padding: 0 !important;
    // border: none !important;
    border-bottom: 1px solid #BBB5AA;
  }
  .ant-table-thead > tr > th::before{
    display:none;
  }
  .ant-table-tbody > tr > td{
    height: 0px; // add to fix bug div in td don't have same height
    padding: 0 !important;
    border-bottom: 1px solid #BBB5AA;
  }
  .ant-table-tbody > tr:last-child > td{
    padding: 0 !important;
    border-bottom: 0px solid #BBB5AA;
  }
  .ant-table-content{
    padding-bottom: 20px;
    background-color: #F9F9F9;
  }
  .ant-table-content > table{
    // width: 940px !important;
  }

}`;

function MobileTable({ ...props }) {
  return (
    <>
      <TableContainer>
        <Table {...props} scroll={{ x: 'fit-content' }} rowKey={(record: any) => record.id} pagination={false} />
      </TableContainer>
    </>
  );
}

export default MobileTable;

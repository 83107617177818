import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { OrderProgress } from './OrderProgress/OrderProgress.component';
import { OrderOverview } from './OrderOverview/OrderOverview.component';
import { OrderDetail } from './OrderDetail/OrderDetail.component';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'app/store';
import {
  fetchBaseOrderLineAsync,
  fetchMyOrderLines,
  fetchOrderLineAsync,
  fetchOrderLinesToken,
  fetch3dOrderConfigs,
  fetchOrderPurchaseInfo,
  selectIs3dOrderLine,
  selectOrderLine,
} from './Order.slice';
import { IsDesktopOrLaptop } from 'utils/mediaQuery';
import { useParams } from 'react-router-dom';
import { fetchResource } from 'features/privateurl/PrivateUrl.slice';
import { selectMe } from 'features/authentication/authSlice';
import { ADMIN_ROLE } from 'utils/constants';
import { isBoolean } from 'lodash';

export const Order = (): JSX.Element => {
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch<AppDispatch>();
  const { resourceToken = '' } = useParams();

  const meInfo = useSelector(selectMe);
  const is3dOrderLine = useSelector(selectIs3dOrderLine);
  const orderLineState = useSelector(selectOrderLine);

  const Params = { resourceToken };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchOrderLineAsync(Params));
      if (!meInfo.roles?.some((role) => role.name === ADMIN_ROLE)) {
        dispatch(fetchMyOrderLines());
      }
    } else {
      dispatch(fetchBaseOrderLineAsync(Params));
    }
  }, [dispatch, isAuthenticated, meInfo, resourceToken]);

  useEffect(() => {
    if (!isAuthenticated) return;

    // Check if current order is a 3D order or not, and get corresponding configs
    // Do not dispatch configs if orderLine or its orderNum is not available.
    console.debug('* Is a 3D order?', is3dOrderLine);
    if (isBoolean(is3dOrderLine)) {
      is3dOrderLine ? dispatch(fetch3dOrderConfigs(Params)) : dispatch(fetchOrderPurchaseInfo(Params));
    }
  }, [orderLineState]);

  useEffect(() => {
    if (!resourceToken) return;
    dispatch(fetchResource({ resourceToken }));
  }, [resourceToken, dispatch]);
  useEffect(() => {
    if (isAuthenticated && !meInfo.roles?.some((role) => role.name === ADMIN_ROLE)) dispatch(fetchOrderLinesToken());
  }, [dispatch, isAuthenticated, meInfo.roles]);
  return (
    <>
      <div className='flex items-center flex-col pb-[52px] px-3 xl:px-0 container '>
        <OrderProgress />
        <OrderOverview />
        {isAuthenticated && IsDesktopOrLaptop() && <OrderDetail show={isAuthenticated} />}
      </div>
    </>
  );
};

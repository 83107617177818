export const PaymentMethodTransform = (method: string) => {
  let methodRs = method;
  switch (method.toLowerCase().trim()) {
    case 'credit':
    case 'manual':
      methodRs = 'Credit Card';
      break;
    case 'wire':
      methodRs = 'Wire Transfer';
      break;
    default:
      break;
  }
  return methodRs;
};

import { TabsProps } from 'antd';
import PaymentMadeMobile from 'features/payment/PaymenntMadeMobile/PaymentMadeMobile.component';
import PaymentDocumentMobile from 'features/document/PaymentDocumentMobile/PaymentDocumentMobile.component';
import { fetchPaymentMades, fetchPaymentShedules } from 'features/payment/Payment.slice';
import PaymentScheduleMobile from 'features/payment/PaymentScheduleMobile/PaymentScheduleMobile.component';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppDispatch } from 'app/store';
import Tabs from 'shared/components/Tabs/Tabs';
import { fetchDocuments } from 'features/document/Document.slice';
import { fetchOrderLineAsync, selectOrderLine, selectIs3dOrderLine } from 'features/order/Order.slice';

const TabContainer = styled.div`
  .ant-tabs-nav::before {
    display:none;
  }
  .ant-tabs-content {
  }
  .ant-tabs {
    &-nav {
      background-color:#F9F9F9;
      .ant-tabs-tab:not(:first-child) {
        margin-left: 35px !important;
      }
    }
  }
}`;

export const OrderDetailMobile = (): JSX.Element => {
  const detailTabStyle = {
    padding: '18px 0px 12.5px 0px',
    marginBottom: 0,
    borderBottom: 0,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { resourceToken = '', tabName = '' } = useParams();
  const is3dOrderLine = useSelector(selectIs3dOrderLine);
  const [defaultActiveKey] = useState(tabName === 'document' ? '3' : tabName === 'payments-made' ? '2' : '1');
  const [activeTab, setActiveTab] = useState(defaultActiveKey);

  const onTabChange = (e: string) => {
    let tabName = '';
    if (e === '1') {
      tabName = 'payments-schedule';
    } else if (e === '2') {
      tabName = 'payments-made';
    } else if (e === '3') {
      tabName = 'document';
    }
    navigate(`/${resourceToken}/${tabName}`);
    setActiveTab(e);
  };

  const orderLineState = useSelector(selectOrderLine);
  useEffect(() => {
    const Params = { resourceToken };
    dispatch(fetchPaymentShedules(Params));
    dispatch(fetchPaymentMades(Params));

    // Here, even though we don't need order configs data,
    // we still have to fetch orderLine and its configs to be able to check the condition: order config response is 404 or not
    // eventually: to hide the 'Document' tab
    dispatch(fetchDocuments(Params));
    dispatch(fetchOrderLineAsync(Params));
  }, [dispatch, orderLineState.configurationId, resourceToken]);

  const [detailTabs, setDetailTabs] = useState<TabsProps['items']>([]);
  const fixedTabs = [
    {
      key: '1',
      label: (
        <h4
          className={`${
            activeTab === '1' ? 'text-gray-7 ' : 'text-base-3'
          } text-xs text-center leading-[130%] mb-[4.5px] font-medium`}>
          Payment Schedule
        </h4>
      ),
      children: <PaymentScheduleMobile />,
    },
    {
      key: '2',
      label: (
        <h4
          className={`${
            activeTab === '2' ? 'text-gray-7 ' : 'text-base-3'
          } text-xs text-center leading-[130%] mb-[4.5px] font-medium`}>
          Payments Made
        </h4>
      ),
      children: <PaymentMadeMobile />,
    },
  ];
  const documentTab = [
    {
      key: '3',
      label: (
        <h4
          className={`${
            activeTab === '3' ? 'text-gray-7 ' : 'text-base-3'
          } text-xs text-center leading-[130%] mb-[4.5px] font-medium`}>
          Documents
        </h4>
      ),
      children: <PaymentDocumentMobile />,
    },
  ];

  useEffect(() => {
    // Do not init with 2 tabs, instead decide 2 or 3 tabs right after condition checking
    // This makes sure the UI is consistent.
    setDetailTabs([...fixedTabs, ...(is3dOrderLine ? documentTab : [])]);
  }, [is3dOrderLine]);

  return (
    <>
      <div className='w-full container flex gap-[40px] mt-[5px] justify-center'>
        <div className='w-full min-h-[150px] overflow-hidden'>
          <TabContainer>
            <Tabs
              onChange={onTabChange}
              items={detailTabs}
              centered={true}
              defaultActiveKey={defaultActiveKey}
              tabBarGutter={0}
              tabBarStyle={detailTabStyle}
              className='p-0'></Tabs>
          </TabContainer>
        </div>
      </div>
    </>
  );
};

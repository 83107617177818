import React, { memo, Ref, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as LockIcon } from '../../icons/lock.svg';
import { ReactComponent as CheckIcon } from '../../icons/check.svg';
export interface ProgressBarProps {
  children: React.ReactElement<StepInfoProps>[];
}
const GradientBar = styled.div`
  transform: translateY(-50%);
  box-shadow: 0px 0px 20px rgba(184, 116, 82, 0.4);
`;
export default memo(function ProgressBar({ children }: ProgressBarProps) {
  const stepInfoRef = useRef<HTMLDivElement | null>(null);
  const whiteBar = useRef<HTMLDivElement | null>(null);
  const [positionPointStep, setPositionPointStep] = useState<number[]>([]);
  const PointStepCurIndex = children.findIndex((e) => e.props.status === 'CURRENT');
  const isFull = children.every((e) => e.props.status === 'PROCESSED');
  const isNotStart = children.every((e) => e.props.status === 'UNPROCESSED');
  const handleWindowSizeChange = () => {
    const tempArray_PoinStep: number[] = [];
    const stepInfoNodes = stepInfoRef.current?.childNodes;
    stepInfoNodes?.forEach((elm, ind) => {
      if (elm instanceof HTMLElement) {
        if (elm.firstChild instanceof HTMLElement) {
          tempArray_PoinStep.push(elm.firstChild?.offsetLeft + elm.firstChild?.clientWidth / 2);
        }
      }
    });
    if (whiteBar.current) tempArray_PoinStep.push(whiteBar.current.clientWidth);
    setPositionPointStep(tempArray_PoinStep);
  };
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [children]);
  return (
    <>
      <div
        ref={whiteBar}
        className='relative mt-5 xl:mt-[58px] w-[calc(100%-2px)] xl:w-full bg-base-2 h-1 xl:h-[15px] rounded-[20px]'>
        <GradientBar
          className={`absolute h-2 xl:h-[25px] left-0 rounded-[20px] bg-gradient-to-r from-gray-5 to-gray-7 top-1/2 transition-all duration-500`}
          style={{
            width: `${isFull ? '100%' : isNotStart ? '0%' : `${positionPointStep[PointStepCurIndex]}px`}`,
          }}></GradientBar>
        <div>
          {children.map((e, index) => {
            return (
              <PointStep
                key={index}
                style={{ left: `${positionPointStep[index]}px` }}
                status={e.props.status}
                backgroundClassName={`linear-gradient(90deg,#D1B888 calc(-${
                  positionPointStep[index]
                }px + 50%),#B77250 calc(${
                  (((positionPointStep[PointStepCurIndex] ?? positionPointStep[positionPointStep.length - 1]) -
                    positionPointStep[index]) *
                    1) /
                  1
                }px + 50%))`}
              />
            );
          })}
        </div>
      </div>
      <div ref={stepInfoRef} className='w-full mt-[26px] xl:mt-[55px] flex xl:space-x-[50px] relative'>
        {children.map((e, index) => {
          return (
            <StepInfo
              key={index}
              className={e.props.className}
              icon={e.props.icon}
              stage={e.props.stage}
              description={e.props.description}
              status={e.props.status}
              descriptionclassName={e.props.descriptionclassName}
            />
          );
        })}
      </div>
    </>
  );
});

interface PointStepProps extends React.HTMLAttributes<HTMLDivElement> {
  status: 'PROCESSED' | 'CURRENT' | 'UNPROCESSED';
  backgroundClassName?: string;
  className?: string;
  ref?: Ref<HTMLDivElement>;
}
const HorizonCenter = styled.div`
  transform: translateX(-50%);
  left: 50%;
`;
const CenterAbsoluteBackground = styled.div`
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;
export function PointStep({
  status = 'UNPROCESSED',
  backgroundClassName = '',
  className = '',
  ref,
  style,
}: PointStepProps) {
  return (
    <CenterAbsoluteBackground
      style={{
        backgroundImage: status === 'UNPROCESSED' ? '' : backgroundClassName,
        boxShadow: status === 'CURRENT' ? '0px 0px 20px rgba(4, 5, 3, 0.25)' : '',
        ...style,
      }}
      ref={ref}
      className={`absolute w-3 h-3 transition-all duration-500 ${
        status !== 'CURRENT' ? 'xl:w-10 xl:h-10' : 'xl:w-[50px] xl:h-[50px]'
      } rounded-full z-0 bg-base-2 ${className}`}>
      <CenterAbsoluteBackground
        className={`absolute w-[6px] h-[6px] border-solid ${
          status !== 'CURRENT' ? 'xl:w-5 xl:h-5' : 'xl:w-[30px] xl:h-[30px] '
        } rounded-full bg-base-2 z-10 ${
          status === 'UNPROCESSED' ? 'border-1 xl:border-2 border-gray-7 border' : ''
        }`}></CenterAbsoluteBackground>
      {status === 'CURRENT' && (
        <HorizonCenter className='absolute w-1 h-1 xl:w-2 xl:h-2 rounded-full bg-gray-7 top-[calc(100%+6px)] xl:top-[calc(100%+10px)]'></HorizonCenter>
      )}
    </CenterAbsoluteBackground>
  );
}

export interface StepInfoProps extends React.HTMLAttributes<HTMLDivElement> {
  descriptionclassName?: string;
  icon?: React.ReactNode;
  status: 'PROCESSED' | 'CURRENT' | 'UNPROCESSED';
  stage?: string;
  description?: string;
  className?: string;
}
const StepInfoCircleWrapper = styled.div<{ status: string }>`
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 0.01%, #dbdbdb 100%);
  box-shadow: ${(p) =>
    p.status === 'CURRENT'
      ? 'inset 2px 2px 4px rgba(132, 132, 132, 0.55), inset -2px -2px 4px #ffffff;'
      : '2px 2px 4px rgba(132, 132, 132, 0.35), -2px -2px 4px #E0E0E0;'};
`;

export function StepInfo({
  icon = <></>,
  status = 'PROCESSED',
  stage = '',
  description = '',
  className = '',
  descriptionclassName,
}: StepInfoProps) {
  return (
    <div className={`flex flex-col xl:flex-row items-center ${className ?? ''}`}>
      <StepInfoCircleWrapper
        status={status}
        className={`w-[30px] h-[30px] xl:w-20 xl:h-20 bg-[#EDEAE2] rounded-full flex justify-center items-center relative xl:min-w-[80px]`}>
        {status !== 'CURRENT' && (
          <div
            className={`w-3 h-3 xl:w-6 xl:h-6 rounded-full absolute top-0 right-0 flex items-center justify-center ${
              status === 'PROCESSED'
                ? 'bg-[#748B89]'
                : 'bg-base-2 border border-solid xl:border-2 border-gray-7 box-content'
            }`}>
            {status === 'PROCESSED' ? (
              <CheckIcon fill='#748B89' />
            ) : (
              <LockIcon className='w-[6px] h-[6px] xl:w-auto xl:h-auto' stroke='#B77250' fill='transparent' />
            )}
          </div>
        )}
        {icon}
      </StepInfoCircleWrapper>
      <div className='flex flex-col text-center xl:text-left mt-2 xl:mt-0 xl:ml-5'>
        <span className='text-base-3 font-normal text-[8px] xl:text-sm !leading-[130%]'>{stage}</span>
        <span
          className={`text-base-3 font-semibold mt-1 text-[10px] xl:text-xl !leading-[130%] ${
            descriptionclassName ?? ''
          }`}>
          {description ?? ''}
        </span>
      </div>
    </div>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import { selectMe } from 'features/authentication/authSlice';
import { getOrderLinesToken } from 'features/order/Order.api';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotFound } from 'shared/components/NotFound/NotFound';
import { ADMIN_ROLE } from 'utils/constants';
function Home() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const [hasOrder, setHasOrder] = useState(true);
  const meInfo = useSelector(selectMe);
  const redirectLoginHandler = useCallback(async (): Promise<void> => {
    localStorage.setItem('APP_BACK_URL', location.pathname);
    await loginWithRedirect();
  }, [location.pathname, loginWithRedirect]);
  useEffect(() => {
    if (!isAuthenticated) {
      redirectLoginHandler();
      return;
    }
    (async () => {
      if (meInfo.roles?.some((role) => role.name === ADMIN_ROLE)) {
        setHasOrder(false);
        return;
      }
      const orderLinesToken = await getOrderLinesToken();
      const firstToken = orderLinesToken[0]?.token ?? '';
      if (firstToken) {
        navigate(`/${firstToken}`);
        return;
      }
      setHasOrder(false);
    })();
  }, [isAuthenticated, meInfo, navigate, redirectLoginHandler]);

  if (!hasOrder) {
    return <NotFound />;
  }
  return <></>;
}

export default Home;

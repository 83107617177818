import { Drawer as AntDrawer, DrawerProps } from 'antd';
import { ReactComponent as CLoseIcon } from 'shared/icons/close.svg';
import styled from 'styled-components';
import { IsDesktopOrLaptop } from 'utils/mediaQuery';

const DrawerWrapper = styled(AntDrawer)`
  .ant-drawer-header {
    padding: 15px 20px;
    border: none;
    .ant-drawer-header-title{
      justify-content: flex-end;
      button{
        margin: 0;
      }
    }
  }
  .ant-drawer-body{
    padding 0;
  }
`;
function Drawer({ ...props }: DrawerProps) {
  return (
    <DrawerWrapper
      maskStyle={{
        backgroundColor: `rgba(189, 189, 189, 0.24)`,
        backdropFilter: `blur(7.5px)`,
        WebkitBackdropFilter: `blur(7.5px)`,
      }}
      contentWrapperStyle={{ boxShadow: 'none' }}
      style={{
        borderRadius: IsDesktopOrLaptop() ? '0px 15px 15px 0px' : '15px 15px 0 0 ',
        boxShadow: '20px 0px 60px rgba(0, 0, 0, 0.25)',
        WebkitBoxShadow: '20px 0px 60px rgba(0, 0, 0, 0.25)',
        // maxHeight: '100vh',
      }}
      closeIcon={<CLoseIcon className='fill-gray-9 hover:fill-gray-5 transition-colors' />}
      {...props}></DrawerWrapper>
  );
}

export default Drawer;

import { Form } from 'antd';
import { useRef } from 'react';
import { ErrorMessage, FormControlLabel } from 'shared/components/Form/Form';
import { getMoneyValidators, MoneyInput, MoneyInputRef } from 'shared/antd/components/MoneyInput';

type PaymentAmountInputProps = {
  defaultValue?: number;
};
export const PaymentAmountInput = ({ defaultValue }: PaymentAmountInputProps): JSX.Element => {
  const moneyRef = useRef<MoneyInputRef>(null);
  // Putting ${name} directly into <FormControlLabel /> won't be able to get name
  const requiredMsg = "'${name}' is required";
  return (
    <Form.Item
      label={<FormControlLabel text={'Amount'} />}
      name='amount'
      rules={[
        {
          required: true,
          message: <ErrorMessage text={requiredMsg} />,
        },
        ...getMoneyValidators(moneyRef),
      ]}
      initialValue={defaultValue}>
      <MoneyInput placeholder={'Enter amount'} ref={moneyRef} name='Amount' autoComplete='transaction-amount' />
    </Form.Item>
  );
};

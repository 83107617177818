import api from 'helpers/api';
import environment from 'app/configs/environment';
import { BaseOrderLine } from 'shared/models/base-order-line.model';
import { OrderLine } from 'shared/models/order-line.model';
import { OrderLineToken } from 'shared/models/token.response.model';
import { Configuration3d, PurchaseInfo } from 'shared/models/configuration.response';
const apiDomain = environment.API_DOMAIN;

export async function getBaseOrderLine(resourceToken: string) {
  const result = await api.get<OrderLine>(`${apiDomain}/api/order-lines/general-info?resource=${resourceToken}`);
  return result.data;
}
export async function getOrderLine(resourceToken: string) {
  const result = await api.get<OrderLine>(`${apiDomain}/api/order-lines?resource=${resourceToken}`);
  return result.data;
}

export async function getMyOrderLines() {
  const result = await api.get<BaseOrderLine[]>(`${apiDomain}/api/order-lines/list`, {
    headers: { 'x-api-version': '1.0' },
  });
  return result.data;
}
export async function getOrderLinesToken() {
  const result = await api.get<OrderLineToken[]>(`${apiDomain}/api/resource/orderlines/get`);
  return result.data;
}

export async function get3dOrderConfigs(resourceToken: string) {
  const result = await api.get<Configuration3d>(`${apiDomain}/api/order-lines/configuration?resource=${resourceToken}`);
  return result.data;
}

export async function getOrderPurchaseInfo(resourceToken: string) {
  const result = await api.get<PurchaseInfo>(`${apiDomain}/api/order-lines/purchase-info?resource=${resourceToken}`);
  return result.data;
}
